import axiosFileDownloader from './axiosFileDownloader';
import axios from './axios';

export default class AttachmentService {

    getparticularAttachments(Ids) {
        return axios.post("api/attachment/list/particular?AttachmentIds=" + Ids);
    }

    DownloadAttachment(id, options) {
        if (options && options.onDownloadProgress) {
            return axiosFileDownloader.get('api/file/Generate?AttachmentID=' + id, options);
        } else {
            return axiosFileDownloader.get('api/file/Generate?AttachmentID=' + id);
        }

    }
}