import React, { Component } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Spinner,
    Table,
    Tooltip,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CustomInput,
    Progress,
    Badge,
    Form,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SHPDocumentsService from '../../services/SHP/SHPDocumentsService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FileSaver from 'file-saver';

export default class SHPViewDocumentModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            document: {},
            requestProgressPercentage: 0,
        };

        this.onDownloadDocument = this.onDownloadDocument.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.document !== nextProps.document) {
            this.setState({ document: nextProps.document });
        }
       
    }

    

    onDownloadDocument(item) {
        item.downloading = true;
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    this.setState({ requestProgressPercentage: percent });
                }
            }
        };
        this.setState({ downloading: true, requestProgressPercentage:0});
        SHPDocumentsService.DownloadAttachment(item.id, options).then(response => {
            FileSaver.saveAs(response.data, item.description);
            item.downloading = false;
            this.setState({ downloading: false, requestProgressPercentage: 0});
        }).catch(error => {
            toast.error(JSON.stringify(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            item.downloading = false;
            this.setState({ downloading: false, requestProgressPercentage: 0 });
        });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { document, requestProgressPercentage, downloading } = this.state;
        return (
            <div>
                {document ?
                    <Modal isOpen={isOpen} size="lg" scrollable={true} toggle={() => toggle(!isOpen)}>
                        <ModalHeader toggle={() => toggle(!isOpen)}>
                           View Document Version 
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}
                                    className={"border-right mt-2"}>
                                    <div>
                                        
                                        <FormGroup>
                                            <Label for="file">File</Label>
                                            <br />
                                            {document.fileName ?
                                                <Button color="link" size={"sm"} className="mt-2"
                                                        title="Click here to download"
                                                    onClick={() => this.onDownloadDocument(document)}
                                                        disabled={downloading}>
                                                    {downloading
                                                        ? <Spinner size="sm"
                                                                   className={"mr-2"}
                                                                   color={"primary"}/>
                                                        :
                                                        <i className="fa fa-paperclip mr-1" aria-hidden="true"/>
                                                    }
                                                    {document.description}
                                                </Button>
                                                : null}
                                        </FormGroup>

                                    </div>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormGroup>
                                        <Label for="description">Description</Label>
                                        <div className="quil-editor-root ">

                                            <ReactQuill
                                                value={document.notes}
                                                readOnly={true}
                                                theme={"bubble"}
                                            />
                                        </div>
                                    </FormGroup>

                                </Col>
                            </Row>
                            
                            <div>
                                {requestProgressPercentage > 0
                                    ? <Progress striped color="primary" value={requestProgressPercentage}
                                        className={"mt-2"} />
                                    : null
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="text-right">
                                <Button color={"secondary"} size={"sm"} onClick={() => toggle(!isOpen)}>Close</Button>
                            </div>
                        </ModalFooter>
                        <ToastContainer />
                    </Modal> : null}
                    </div>
        )
    }
}