import axios from '../axios';

class UserInboxService {

    static Instance() {
        return new UserInboxService();
    }

    getUserInbox(type, request) {
        let url = "api/user/inbox/list/default";
        switch (type) {
            case "default":
                url = "api/user/inbox/list/default";
                break;
            case "important":
                url = "api/user/inbox/list/important";
                break;
            case "archive":
                url = "api/user/inbox/list/archive";
                break;
            case "trash":
                url = "api/user/inbox/list/trash";
                break;
            case "all":
                url = "api/user/inbox/list/all";
                break;
            case "sent":
                url = "api/user/inbox/list/sent";
                break;
            case "unread":
                url = "api/user/inbox/list/unread";
                break;
            case "template":
                url = "api/user/inbox/list/template";
                break;

        }
        return axios.post(url, request);
    }

    markMessage(id, action) {
        let url = "api/user/inbox/update/mark/read?id=" + id;
        switch (action) {
            case "read":
                url = "api/user/inbox/update/mark/read?id=" + id;
                break;
            case "important":
                url = "api/user/inbox/update/mark/important?id=" + id;
                break;
            case "archive":
                url = "api/user/inbox/update/mark/archive?id=" + id;
                break;
            case "trash":
                url = "api/user/inbox/update/mark/active?id=" + id;
                break;

        }
        return axios.put(url);
    }

    handleMessageMarkLoadingChange(action, loading, item, isLoading) {
        switch (action) {
            case "read":
                loading.markRead = isLoading ? item.id : false;
                break;
            case "important":
                loading.markImportant = isLoading ? item.id : false;
                break;
            case "archive":
                loading.markArchive = isLoading ? item.id : false;
                break;
            case "trash":
                loading.markTrash = isLoading ? item.id : false;
                break;
        }
        return loading;
    }

    saveMessage(request) {
        let url = "api/user/inbox/save";

        return axios.post(url, request);
    }

    saveTemplate(request) {
        let url = "api/user/inbox/save/template";

        return axios.post(url, request);
    }
}

export default UserInboxService.Instance();