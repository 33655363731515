import React, {Component} from 'react';
import {
    Tooltip, Breadcrumb, BreadcrumbItem, Card, CardBody, Spinner, Table, Button, Badge,
} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import {findIndex, getLocalDateStringFromUtcDateString,handleErrorMessage} from '../../services/CommonService';
import userInboxService from "../../services/user/UserInboxService";
import ManageUserInboxTemplateModal from "../../components/user/ManageUserInboxTemplateModal";
import ConfirmModal from '../../components/modal/ConfirmModal';
import UserService from "../../services/UserService";
import {userInboxMessageCategoryByValue} from '../../store/UserInboxMessageCategory';
import classnames from "classnames";

import cloneDeep from 'lodash/cloneDeep';

const newTemplate = {
    to: "",
    fromEmail: "",
    ccEmail: "",
    subject: "",
    body: "",
    shortMessage: "",
    isRead: true,
    isActive: true,
    isArchived: false,
    isImportant: false,
    isTemplate: true,
    category: "",
    isValidated: true

};
export default class ManageUserInboxTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            loading: {
                templates: false,
                saveTemplate: false,
                deleteTemplate: false,
                archiveTemplate: false
            },
            workingTemplate: {},
            isOpenConfirmDeleteModal: false,
            isOpenTemplateModal: false,
        };

        this.refresh = this.refresh.bind(this);
        this.toggleTemplateFormModal = this.toggleTemplateFormModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.archiveTemplate = this.archiveTemplate.bind(this);
        this.userService = new UserService();
    }

    componentDidMount() {
        this.refresh(false);
    }

    refresh(silent) {
        this.getUserInboxTemplates(silent);
    }

    getUserInboxTemplates(silent) {
        let {loading, templates} = this.state;
        if (!silent) {
            loading.templates = true;
            this.setState({loading});
        }
        userInboxService.getUserInbox('template').then(response => {
            if (response.data) {
                templates = response.data;
            }
            loading.templates = false;
            this.setState({templates, loading});
        }).catch(error => {
            loading.templates = false;
            this.setState({loading});
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(change, key) {
        let {workingTemplate} = this.state;
        switch (key) {
            case "category":
                workingTemplate[key] = change;
                this.setState({workingTemplate});
                break;
            case "fromEmail":
                workingTemplate[key] = change;
                this.setState({workingTemplate});
                break;
            case "to":
                workingTemplate[key] = change;
                this.setState({workingTemplate});
                break;
            case "ccEmail":
                workingTemplate[key] = change;
                this.setState({workingTemplate});
                break;
            case "subject":
                workingTemplate[key] = change;
                this.setState({workingTemplate});
                break;
            case "shortMessage":
                workingTemplate[key] = change;
                this.setState({workingTemplate});
                break;
            case "body":
                workingTemplate[key] = change;
                this.setState({workingTemplate});
                break;
            case "isValidated":
                workingTemplate[key] = change;
                this.setState({workingTemplate});
                break;
        }
    }

    handleSubmit(template) {
        let {loading, templates} = this.state;
        loading.saveTemplate = true;
        this.setState({loading});
        userInboxService.saveTemplate(template).then(response => {
            if (response.data) {
                let index = findIndex(templates, 'id', response.data.id);
                if (index > -1) {
                    templates[index] = template;
                } else {
                    templates.push(template);
                }
            }
            loading.saveTemplate = false;
            this.setState({templates, loading, isOpenTemplateModal: false, workingTemplate: {}});
            toast.success("Saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            loading.saveTemplate = false;
            this.setState({loading});
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleItemAction(operation, template) {
        let {workingTemplate} = this.state;

        switch (operation) {
            case "new":
                workingTemplate = cloneDeep(template);
                if (!workingTemplate.fromEmail) {
                    workingTemplate.fromEmail = this.userService.getLoggedInUser().emailAddress;
                }
                this.setState({workingTemplate, isOpenTemplateModal: true});
                break;
            case "edit":
                workingTemplate = cloneDeep(template);
                this.setState({workingTemplate, isOpenTemplateModal: true});
                break;
            case "delete":
                workingTemplate = cloneDeep(template);
                this.setState({workingTemplate, isOpenConfirmDeleteModal: true});
                break;
            case "archive":
                workingTemplate = cloneDeep(template);
                this.archiveTemplate(workingTemplate);
                break;
        }
    }

    toggleTemplateFormModal(change) {
        this.setState({isOpenTemplateModal: change});
    }

    toggleConfirmDeleteModal(change) {
        let {workingTemplate} = this.state;
        workingTemplate = {};
        this.setState({isOpenConfirmDeleteModal: change, workingTemplate});
    }

    deleteTemplate() {
        let {workingTemplate, loading} = this.state;
        loading.deleteTemplate = true;
        this.setState({loading});
        userInboxService.markMessage(workingTemplate.id, "trash").then(response => {
            loading.deleteTemplate = false;
            workingTemplate = {};
            this.setState({loading, isOpenConfirmDeleteModal: false, workingTemplate});
            this.getUserInboxTemplates(true);
            toast.success("Deleted!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            loading.deleteTemplate = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    archiveTemplate(workingTemplate) {
        let {loading} = this.state;
        loading.archiveTemplate = workingTemplate.id;
        this.setState({loading, workingTemplate});
        userInboxService.markMessage(workingTemplate.id, "archive").then(response => {
            loading.archiveTemplate = false;
            workingTemplate = {};
            this.setState({loading, workingTemplate});
            this.getUserInboxTemplates(true);
            toast.success("Success!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            loading.archiveTemplate = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    render() {
        let {templates, loading, workingTemplate, isOpenTemplateModal, isOpenConfirmDeleteModal} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Manage User Inbox Templates</BreadcrumbItem>
                </Breadcrumb>
                <div className={"mt-4"}>
                    {
                        loading.templates
                            ? <Spinner color={"primary"}/>
                            : <div>
                                <div className="mb-2">
                                    <Button color="primary" onClick={() => this.handleItemAction("new", newTemplate)}><i
                                        className="fa fa-plus mr-2" aria-hidden="true"/>Add template</Button>
                                </div>

                                <Card>
                                    <CardBody>

                                        <Table responsive hover>
                                            <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Template for</th>
                                                <th>Subject</th>
                                                <th>Short Message</th>
                                                <th className={"text-center"}>Status</th>
                                                <th className={"text-center"}>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {templates.map((item, index) =>
                                                <tr key={index}

                                                >
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>
                                                        {userInboxMessageCategoryByValue[item.category]}
                                                    </td>

                                                    <td>
                                                        {item.subject}
                                                    </td>
                                                    <td>
                                                        {item.shortMessage}
                                                    </td>
                                                    <td className={"text-center"}>
                                                        {
                                                            item.isArchived
                                                                ? <Badge color="secondary">Archived</Badge>
                                                                : <Badge color="success">Live</Badge>
                                                        }
                                                    </td>
                                                    <td className={"text-center"}>
                                                        <div>
                                                            <a title={"click here to edit item"}
                                                               href="javascript:void(0)"
                                                               style={{textDecoration: "none"}}
                                                               onClick={() => this.handleItemAction("edit", item)}
                                                            >
                                                                <i
                                                                    className="mt-2 fa fa-pencil fa-lg"
                                                                    aria-hidden="true"/>
                                                            </a>
                                                            <span className={"text-muted"}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                            {
                                                                (loading.archiveTemplate === item.id)
                                                                    ? <Spinner size={"sm"} color={"primary"}/>
                                                                    : <a title={"click here to acrhive"}
                                                                         href="javascript:void(0)"
                                                                         style={{textDecoration: "none"}}
                                                                         onClick={() => this.handleItemAction("archive", item)}
                                                                    >
                                                                        <i
                                                                            className="mt-2 fa fa-archive fa-lg"
                                                                            aria-hidden="true"/>
                                                                    </a>
                                                            }
                                                            <span className={"text-muted"}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                            <a title={"click here to delete"}
                                                               href="javascript:void(0)"
                                                               style={{textDecoration: "none"}}
                                                               onClick={() => this.handleItemAction("delete", item)}
                                                            >
                                                                <i
                                                                    className="mt-2 fa fa-trash-o fa-lg"
                                                                    aria-hidden="true"/>
                                                            </a>
                                                        </div>

                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </div>
                    }
                </div>
                {isOpenTemplateModal ?
                    <ManageUserInboxTemplateModal
                        isOpen={isOpenTemplateModal}
                        toggle={this.toggleTemplateFormModal}
                        template={workingTemplate}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}
                        loading={loading.saveTemplate}
                    />
                    : null
                }

                <ConfirmModal
                    isOpen={isOpenConfirmDeleteModal}
                    toggle={this.toggleConfirmDeleteModal}
                    handleCancel={() => this.toggleConfirmDeleteModal(false)}
                    handleSubmit={() => this.deleteTemplate()}
                    primaryMessage={"Confirm Delete"}
                    secondaryMessage={"Are you sure you want to delete rule?"}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                    loading={loading.deleteTemplate}
                />
                <ToastContainer/>


            </div>
        );
    }
}