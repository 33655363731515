import axios from "../axios";
export default class FineLineSearchService{
    searchFineLine(searchText)
    {
        return axios.get('api/swish/dw/search/dimStockItems?searchText='+searchText)
    }

    getFinelineWithID(dimStockItemID) {
        return axios.get('api/swish/dw/search/dimStockItem?dimStockItemID=' + dimStockItemID)
    }
}