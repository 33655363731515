import React, {Component} from 'react';
import {Redirect} from "react-router-dom";

export default class SHPDocumentCard extends Component {

    render() {

        return (
            <Redirect to={'/shp/documents'}/>
        );
    }
}