import React, {Component} from 'react';
import AsyncSelect from "react-select/async/dist/react-select.esm";
import FineLineSearchService from "../../services/SHP/FineLineSearchService";
import {toast,ToastContainer} from "react-toastify";
import {handleErrorMessage} from "../../services/CommonService";

export default class SearchFineLine extends Component {
    constructor() {
        super();
        this.state = {
            selectedOption: null,
            options: [],
            loadingOptions: false,
        }
        this.fineLineSearchService = new FineLineSearchService();
        this.prepareFetchOptions = this.prepareFetchOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(change) {
        this.setState({selectedOption: change}, () => {
            this.props.handleSelection(change ? change.value : null);
        });
    };

    componentDidMount() {
        this.fetchOptions("");
        if (this.props.defaultValue) {
            this.fineLineSearchService.getFinelineWithID(this.props.defaultValue).then(response => {
                let option = [];
                option = {
                    label: response.data.stockItemCd + " | " + response.data.stockItemDescription,
                    value: response.data
                };
                this.setState({ selectedOption: option });
            }).catch(error => {
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        }
    }

    fetchOptions(searchText, callback) {
        let options = [];
        this.setState({loadingOptions: true});
        this.fineLineSearchService.searchFineLine(searchText).then(response => {
            let data = response.data;
            options = [];
            for (let i = 0; i < data.length; i++) {
                options[i] = {
                    label: data[i].stockItemCd + " | " + data[i].stockItemDescription,
                    value: data[i]
                };
            }

            this.setState({options, loadingOptions: false});
            if (callback) {
                return callback(options)
            } else {
                return options;
            }
        }).catch(error => {
                this.setState({loadingOptions: false});
                console.log(error);
                toast.error(handleErrorMessage(error));
            }
        );
    }

    prepareFetchOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 1) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.options);
            }
        }
    };

    render() {
        let {selectedOption, loadingOptions, options} = this.state;
        return (
            <div>
                <AsyncSelect
                    isLoading={loadingOptions}
                    isClearable={true}
                    defaultOptions={options}
                    loadOptions={this.prepareFetchOptions}
                    onChange={this.handleChange}
                    value={selectedOption}
                    placeholder={"Fineline search..."}
                    isDisabled={this.props.disabled}
                />
                <ToastContainer/>
            </div>
        )
    }
}