import React, { Component } from 'react';
import {
    Modal, ModalHeader, ModalBody, 
    Button,
    Spinner,
    Progress,
    ModalFooter
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import SHPDocumentsService from '../../services/SHP/SHPDocumentsService';

export default class SHPVersionControlModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingOldVersion: false,
            loadingNewVersion: false,
            requestProgressPercentage:0
        };
        this.saveDocumentOldVersion = this.saveDocumentOldVersion.bind(this);
        this.saveDocumentNewVersion = this.saveDocumentNewVersion.bind(this);

    }

    saveDocumentNewVersion() {
        this.setState({ loadingNewVersion: true })
        this.props.req.isNewVersion = true;
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    this.setState({ requestProgressPercentage: percent });
                }
            }
        };

        SHPDocumentsService.updateDocument(this.props.req, options).then(response => {
            if (response.status === 200 || response.status === '200') {
                this.props.handlerCloseModal(response.data, false);
                toast.success("Document updated successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({ saving: false, requestProgressPercentage: 0, loadingNewVersion:false });
            }
        }).catch(error => {
            this.setState({ saving: false,loadingNewVersion: false, requestProgressPercentage: 0})
            toast.error(JSON.stringify(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    saveDocumentOldVersion() {
        this.setState({loadingOldVersion:true})
        this.props.req.isNewVersion = false;
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    this.setState({ requestProgressPercentage: percent });
                }
            }
        };

        SHPDocumentsService.updateDocument(this.props.req, options).then(response => {
            if (response.status === 200 || response.status === '200') {
                this.props.handlerCloseModal(response.data, false);
                toast.success("Document updated successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({ saving: false, requestProgressPercentage: 0, loadingOldVersion:false});
            }
        }).catch(error => {
            toast.error(JSON.stringify(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ saving: false, loadingOldVersion: false, requestProgressPercentage: 0});
        });
    }

    

    render() {
        let { isOpen, toggle } = this.props;
        let { requestProgressPercentage} = this.state;
        
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                   Save changes
                </ModalHeader>
                <ModalBody>
                    <p className="text-center"> You want to save changes to this document by</p> 
                    <div className="text-center">
                        <Button color={"primary"} size={"sm"} onClick={this.saveDocumentNewVersion} disabled={this.state.loadingNewVersion || this.state.loadingOldVersion}>
                            {this.state.loadingNewVersion
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />
                            }
                            Creating new version</Button>
                        <Button color={"info"} size={"sm"} className="ml-2" onClick={this.saveDocumentOldVersion} disabled={this.state.loadingNewVersion || this.state.loadingOldVersion}>
                            {this.state.loadingOldVersion
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    style={{ color: "white" }} />
                                : <i className="fa fa-floppy-o mr-2" />
                            }Using same version</Button>

                    </div>

                    <div>
                        {requestProgressPercentage > 0
                            ? <Progress striped color="primary" value={requestProgressPercentage}
                                className={"mt-2"} />
                            : null
                        }
                    </div>
                    <ToastContainer/>
                </ModalBody>
                <ModalFooter>
                    <div className='text-right'>
                        <Button type='secondary' onClick={() => toggle(!isOpen)} size='sm'>
                            <i className='fa fa-times mr-2'/>
                            Cancel</Button>
                    </div>
                </ModalFooter>
                
            </Modal>
        )
    }
}