import React, { Component } from 'react';
import {
    Modal, ModalBody, Row, Col, ModalHeader, Table,
    Badge, FormGroup, Label, ModalFooter, Button
} from 'reactstrap';
import UploadFiles from '../attachment/UploadFiles';
import SearchFineLine from '../search/SearchFineLine';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class SHPSnapshotModal extends Component {
    

    render() {
        let { isOpen, toggle, snapshot } = this.props;
        snapshot.tags = [];
        if (snapshot.shpDocumentSnapshotSHPTags && snapshot.shpDocumentSnapshotSHPTags.length !== 0) {

            (snapshot.shpDocumentSnapshotSHPTags || []).map(type => {
                let option = {
                    label: type.shpTag.name,
                    value: type.shpTagID
                }
                snapshot.tags.push(option);

            });
        }

        if (snapshot.shpTags && snapshot.shpTags.length !== 0) {

            (snapshot.shpTags || []).map(type => {
                let option = {
                    label: type.shpTag.name,
                    value: type.shpTagID
                }
                snapshot.tags.push(option);

            });
        }

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Version {this.props.version}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12} lg={12} md={6} sm={12} xs={12}
                            className={"mt-2"}>
                            <div>
                                <FormGroup>
                                    <Label for="title"><strong>Title</strong></Label>
                                    <br />
                                    {snapshot.title}
                                </FormGroup>

                                <Label for="title"><strong>Fineline</strong></Label>
                                <Table hover bordered size={"sm"} responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: '180px' }}>Fineline</th>
                                            <th>Prodcode</th>
                                            <th>Sub class</th>
                                            <th>Sub department</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(snapshot.shpDocumentSnapshotFinelineItems || []).map((fineline, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <FormGroup>
                                                        <SearchFineLine defaultValue={fineline.swishDWDimStockItemID} />
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    {fineline.prodCode}
                                                </td>
                                                <td>
                                                    {fineline.subClass}
                                                </td>
                                                <td>
                                                    {fineline.subDepartment}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                {(snapshot.shpDocumentSnapshotSuppliers && snapshot.shpDocumentSnapshotSuppliers.length > 0) ?
                                    <FormGroup>
                                        <Label><strong>Supplier</strong></Label>
                                        <br />
                                        {(snapshot.shpDocumentSnapshotSuppliers || []).map((supplier, supplierIndex) => (
                                            <Badge key={supplierIndex}
                                                color="primary"
                                                className={"m-1"}
                                            >{supplier.supplierCode} - {supplier.company} </Badge>
                                        ))}
                                    </FormGroup> : null}

                                {snapshot.description ?
                                    <FormGroup>
                                        <Label for="description"><strong>Description</strong></Label>
                                        <br />
                                        {snapshot.description}
                                    </FormGroup> : null}

                                {(snapshot.tags && snapshot.tags.length > 0)
                                    ? <FormGroup >
                                        <Label><strong>Tag</strong></Label>
                                        <br />
                                        {(snapshot.tags || []).map((tag, index) => (
                                            <Badge color='light' key={index}>{tag.label}</Badge>
                                        ))}
                                    </FormGroup> : null}

                                {snapshot.attachmentIDs ?
                                    <div>
                                        <strong>Attachments:</strong>
                                        <UploadFiles
                                            isDownloadAllFilesBtnVisible={true}
                                            isReadOnly={true}
                                            attachmentIDs={snapshot.attachmentIDs}
                                            documentID={0} />
                                    </div> : null}

                                <FormGroup>
                                    <Label for="notes"><strong>Notes</strong></Label>
                                    <div className="quil-editor-root ">
                                        <ReactQuill
                                            value={snapshot.notes || ''}
                                            readOnly={true}
                                            theme={"bubble"}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className='text-right'>
                        <Button type='secondary' onClick={() => toggle(!isOpen)} size='sm'>
                            <i className='fa fa-times mr-2' />
                            Cancel</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}