import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row, Col,
    Button, Badge,
    Label, Input, FormText,
    Card, CardBody,
    Spinner
} from 'reactstrap';
import {getLocalDateStringFromUtcDateString} from '../../services/CommonService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Link} from "react-router-dom";
import AttachmentComponent from '../../pages/Attachment/AttachmentComponent';

export default class ViewUserInboxMessageModal extends Component {

    render() {
        let {isOpen, toggle, message} = this.props;


        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {message.subject}
                </ModalHeader>
                <ModalBody style={{minHeight: 300}}>
                    <div className="pl-3">
                        <div>
                            <Row>
                                <Col xl={9} lg={9} md={8} sm={12} xs={12}>
                                    <div className="mb-2">
                                        <div>
                                            <span
                                                className="mr-2 text-muted">From:</span>{message.from ? message.from.fullName : ""}
                                            <small className="ml-2">{message.fromEmail}</small>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <div>
                                            <span
                                                className="mr-2 text-muted">To:</span>{message.to ? message.to.fullName : ""}
                                            <small className="ml-2">{message.toEmail}</small>
                                        </div>
                                    </div>
                                    {message.ccEmail
                                        ? <div className="mb-2">
                                            <div>
                                                <span
                                                    className="mr-2 text-muted">cc:</span>{message.cc ? message.cc.fullName : ""}
                                                <small className="ml-2">{message.ccEmail}</small>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </Col>
                                <Col xl={3} lg={3} md={4} sm={12} xs={12} className={"text-right"}>
                                    <div className={"text-muted text-right"}>
                                        <small>{getLocalDateStringFromUtcDateString(message.createdOn, 'DD MMM YYYY')}</small>
                                    </div>
                                    <div className={"text-muted text-right"}>
                                        <small>{getLocalDateStringFromUtcDateString(message.createdOn, 'hh:mm A')}</small>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {
                            message.shortMessage
                                ? <div>
                                    <hr/>
                                    <p><strong>{message.shortMessage}</strong></p>
                                </div>
                                : null
                        }


                        {message.link
                            ? <div>
                                <hr/>
                                <Link style={{textDecoration: "none"}} to={message.link}>
                                    <Button outline color={"primary"} size={"sm"}>
                                        <i className="fa fa-external-link mr-2" aria-hidden="true"/>
                                        View more
                                    </Button>
                                </Link>
                            </div>
                            : null
                        }
                        <hr/>
                    </div>

                    <div className="quil-editor-root ">
                        < ReactQuill
                            value={message.body
                            }
                            readOnly={true}
                            theme={"bubble"}
                        />
                    </div>
                    <div className="pl-3">
                        {
                            message.attachmentIDs
                                ?
                                <div>
                                    <hr/>
                                    <AttachmentComponent ids={message.attachmentIDs}/>
                                </div>
                                : null
                        }
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color={"primary"} onClick={() => toggle(!isOpen)}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }


}