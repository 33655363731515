import React, {Component} from 'react';
import Notification from './notification.component'
import {Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink} from 'reactstrap';
import {Link} from "react-router-dom";
import {EventBus} from "../../components/events/event";

export default class UserMenuDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openRoleActivationModal: false
        };

        this.logout = this.logout.bind(this);
        this.reset = this.reset.bind(this);
    }



    logout() {
        this.reset();
        EventBus.publish('login-logout', {});
    }

    reset() {
        localStorage.clear();
    }


    render() {
        let greetingText = "";
        if (this.props.user.firstName) {
            greetingText = this.props.user.firstName;

        }
        return (
            <div>
                <UncontrolledDropdown nav inNavbar className={"listStyleNone"}>
                    <DropdownToggle nav caret className={"hoverableItem"}>
                        <i className="fa fa-fw fa-user mr-2" aria-hidden="true"/> {greetingText.toUpperCase()}
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <Link style={{textDecoration: "none"}}
                                  to={"/profile"}>
                                <i className="fa fa-fw fa-pencil mr-2" aria-hidden="true"/>Edit Profile
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link style={{textDecoration: "none"}}
                                  to={"/user/inbox"}>
                                <i className="fa fa-fw fa-inbox mr-2" aria-hidden="true"/>Inbox
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link
                                style={{textDecoration: "none"}}
                                onClick={this.logout}
                                to={"/"}><i className="fa fa-fw fa-power-off mr-2" aria-hidden="true"/>Logout
                            </Link>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>


        );
    }
}

