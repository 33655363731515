import React, {Component} from 'react';
import MaterialTitlePanel from "../header/header.component";
import {Link} from "react-router-dom";


import classNames from 'classnames';
import UserService from "../../services/UserService";
import {EventBus} from "../../components/events/event";
import Menu, {SubMenu, MenuItem} from 'rc-menu';
import '../../assets/css/app/rc-menu.css';

const styles = {
    sidebar: {
        width: 200,
    }
};

export default class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            selectedMenuItem: "",
            selectedSubMenuItem: ""
        };
        this.userService = new UserService();
        this.logout = this.logout.bind(this);
        this.reset = this.reset.bind(this);
        this.toggle = this.toggle.bind(this);
        this.selectMenuItem = this.selectMenuItem.bind(this);
    }

    componentDidMount() {
        this.setState({
            selectedMenuItem: "",
            selectedSubMenuItem: ""
        });
    }

    logout() {
        this.reset();
        EventBus.publish('login-logout', {});
    }

    reset() {
        localStorage.clear();
        this.setState({
            selectedMenuItem: "",
            selectedSubMenuItem: ""
        });
    }


    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    selectMenuItem(selectedMenuItem, selectedSubMenuItem) {
        this.setState({
            selectedMenuItem: selectedMenuItem,
            selectedSubMenuItem: selectedSubMenuItem
        });
    }

    getMenuItem(menuItem, isRootMenu) {

        if (menuItem && menuItem.type !== 'link' && menuItem.children) {
            menuItem.children.sort(function (x, y) {
                return (x.sortIndex - y.sortIndex);
            })
        }

        return ((menuItem.type === 'link') ?
            <MenuItem key={menuItem.key}>
                <Link style={{textDecoration: "none"}}
                      onClick={() => this.selectMenuItem(menuItem.title, "")}
                      to={menuItem.path}>
                    {menuItem.icon ? <span><i className={menuItem.icon}
                                              aria-hidden="true"/>&nbsp;&nbsp;</span> : null}{menuItem.title}
                </Link>
            </MenuItem>


            : <SubMenu
                title={menuItem.path ?
                    <Link to={menuItem.path} style={{textDecoration: "none"}}>{menuItem.title}</Link> :
                    <a href={"javascript:void(0)"} style={{textDecoration: "none"}}>
                        {menuItem.icon ? <span><i className={menuItem.icon}
                                                  aria-hidden="true"/>&nbsp;&nbsp;</span> : null}{menuItem.title}
                    </a>}
                key={menuItem.key}>
                {menuItem.children.map((childrenItem) => {
                        return this.getMenuItem(childrenItem, false)
                    }
                )}
            </SubMenu>);
    }


    render() {
        if (!this.props.isLoggedIn) {
            return null;
        }

        if (this.props.user && this.props.user.menu && this.props.user.menu.items && this.props.user.menu.items.length > 0) {

            this.props.user.menu.items.sort(function (x, y) {
                return (x.sortIndex - y.sortIndex);
            });

            return (<MaterialTitlePanel isLoggedIn={this.props.isLoggedIn} style={styles.sidebar} type={"sidebar"}>
                <Menu mode={this.props.docked ? "vertical-left" : "inline"}
                      style={{borderBottom: "none", boxShadow: "none"}}>
                    <MenuItem>
                        <div className="logo-container">
                            <Link to={"/"}> <img
                                alt=""
                                src="https://winonline.blob.core.windows.net/shp/img/logo/shp-logo.PNG"/>
                            </Link>
                        </div>
                    </MenuItem>
                    {this.props.user.menu.items.map((menuItem) => {
                            return this.getMenuItem(menuItem, true)
                        }
                    )}
                </Menu>
            </MaterialTitlePanel>)
        }
        return null;
    }
};



