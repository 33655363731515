import React, { Component } from 'react';
import {
    Button,
    Spinner,
    Table,
    Tooltip,
    Card,
    CardBody,
    CardHeader,
    Badge,
    CardFooter,
    Row,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    Progress
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SHPDocumentsService from '../../services/SHP/SHPDocumentsService';
import { getLocalDateStringFromUtcDateString, handleErrorMessage } from '../../services/CommonService';
import FileSaver from 'file-saver';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import SHPViewDocumentModal from './SHPViewDocumentModal';

export default class SHPDocumentHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            documentHistory: [],
            loading: {
                documentHistoryList: false
            },
            selectedDocument: {},
            isOpenModal: false,
            requestProgressPercentage: 0,
        };

        this.onDownloadDocument = this.onDownloadDocument.bind(this);
        this.getDocumentHistory = this.getDocumentHistory.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }


    componentDidMount() {
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            this.setState({ attachmentId: searchParams.uuid }, () => this.getDocumentHistory())
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.search) {
            let searchParams = queryString.parse(nextProps.location.search);
            this.setState({ attachmentId: searchParams.uuid }, () => this.getDocumentHistory())
        }
    }

    getDocumentHistory() {
        if (this.state.attachmentId !== 0) {
            let { loading } = this.state;
            loading.documentHistoryList = true;
            this.setState({ loading });
            SHPDocumentsService.getDocumentHistory(this.state.attachmentId).then(response => {
                loading.documentHistoryList = false;
                this.setState({ documentHistory: response.data, loading });
            }).catch(error => {
                loading.documentHistoryList = false;
                this.setState({ loading });
                toast.error(handleErrorMessage(error));
            });
        }
    }

    getDate(date, dateFormat) {
        if (date) {
            let selectedDate = getLocalDateStringFromUtcDateString(date, dateFormat);

            return selectedDate;
        }
    }

    toggle = targetName => {
        if (!this.state[targetName]) {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: true
                }
            });
        } else {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: !this.state[targetName].tooltipOpen
                }
            });
        }
    };

    isToolTipOpen = targetName => {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    };

    getBadgeColor(event) {
        let result = "";
        switch (event) {
            case "DELETE":
                result = "danger";
                break;
            case "UPLOAD":
                result = "primary";
                break;
            case "REPLACE":
                result = "warning";
                break;
            case "DOWNLOAD":
                result = "success";
                break;
            case "UPDATE":
                result = "info";
                break;


        }

        return result;
    }

    onDownloadDocument(item) {
        item.downloading = true;
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    item.requestProgressPercentage = percent;
                }
            }
        };
        item.requestProgressPercentage = 0;
        this.setState({ downloading: true });
        SHPDocumentsService.generateFileFromAttachmentHistory(item.id, options).then(response => {
            FileSaver.saveAs(response.data, item.description);
            item.downloading = false;
            item.requestProgressPercentage = 0;
            this.setState({ downloading: false });
        }).catch(error => {
            toast.error(JSON.stringify(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            item.downloading = false;
            item.requestProgressPercentage = 0;
            this.setState({ downloading: false });
        });
    }

    toggleModal(change) {
        this.setState({ isOpenModal: change });
    }

    viewDocument(item) {
        this.setState({ selectedDocument: item.previousAttachmentVersion, isOpenModal: true })
    }

    viewReplacedVersionDocument(item) {
        this.setState({ selectedDocument: item.replacedAttachmentVersion, isOpenModal: true })
    }

    getButton(event) {
        let result = "";
        switch (event) {
            case "DOWNLOAD":
                result = "View Downloaded Document";
                break;
            case "REPLACE":
                result = "View Previous Version";
                break;
            case "UPLOAD":
                result = "View Uploaded Document";
                break;
            case "UPDATE":
                result = "View Updated Document";
                break;
        }
        return result;
    }

    render() {
        let { documentHistory, loading } = this.state;

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/shp/documents">Documents</Link></BreadcrumbItem>
                    <BreadcrumbItem active>History</BreadcrumbItem>
                </Breadcrumb>

                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <h5>Document History</h5>
                                </Col>

                            </Row>
                        </CardHeader>
                        <CardBody>
                            {loading.documentHistoryList
                                ? <Spinner size={"sm"} color={"primary"} />
                                : <div>
                                    <Table responsive={true} hover={true} striped={true} size={"sm"}>
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                <th>Event</th>
                                                <th>FileName</th>
                                                <th>Initiated By</th>
                                                <th>Initiated On</th>
                                                {/* <th>Action</th>*/}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(documentHistory || []).map((item, index) => {
                                                return <tr key={index}
                                                    className={"hand-cursor"}
                                                >
                                                    <td>{index + 1}</td>
                                                    <td><Badge size={"sm"} color={this.getBadgeColor(item.event)}>{item.event}</Badge></td>
                                                    <td>
                                                        {item.fileName ?
                                                            <div>
                                                                <Button color="link" size={"sm"}
                                                                    className="mt-2"
                                                                    title="Click here to download"
                                                                    onClick={() => this.onDownloadDocument(item)}
                                                                    disabled={item.downloading}>
                                                                    {item.downloading
                                                                        ? <Spinner size="sm"
                                                                            className={"mr-2"}
                                                                            color={"primary"} /> :
                                                                        <i className="fa fa-paperclip mr-1"
                                                                            aria-hidden="true" />
                                                                    }
                                                                    {item.description}
                                                                </Button>
                                                                <div>
                                                                    {item.requestProgressPercentage > 0
                                                                        ? <Progress striped color="primary" value={item.requestProgressPercentage}
                                                                            className={"mt-2"} />
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </td>
                                                    <td>
                                                        <span id={`td-${index}`}>{item.createdByUser.name}<Tooltip placement="right" isOpen={this.isToolTipOpen(`td-${index}`)}
                                                            target={`td-${index}`} toggle={() => this.toggle(`td-${index}`)}>
                                                            {item.createdByUser.emailAddress}
                                                        </Tooltip></span></td>
                                                    <td>
                                                        {this.getDate(item.createdOn, "DD MMM YYYY")}
                                                        <br />
                                                        {this.getDate(item.createdOn, "hh:mm A")}
                                                    </td>
                                                    {/*<td>
                                                    
                                                    <Button color="primary" size={"sm"} block={true}
                                                        onClick={this.viewDocument.bind(this, item)}>{this.getButton(item.event)}</Button>
                                                    {item.event == "REPLACE" ?
                                                        <Button color="primary" size={"sm"} block={true}
                                                            onClick={this.viewReplacedVersionDocument.bind(this, item)}>View Replaced Version</Button>
                                                        :null
                                                        }
                                                </td>*/}

                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </CardBody>
                        <CardFooter>
                            <div className="text-right">
                                <Link className="btn btn-sm btn-secondary"
                                    to={"/shp/manage/documents?" + queryString.stringify({ uuid: this.state.attachmentId })}>
                                    <i className="fa fa-times mr-2" />Close</Link>
                            </div>
                        </CardFooter>
                    </Card>
                    <SHPViewDocumentModal isOpen={this.state.isOpenModal}
                        toggle={this.toggleModal}
                        document={this.state.selectedDocument} />
                    <ToastContainer />
                </div>
            </div>


        )
    }
}