import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Badge,
} from 'reactstrap';
import classnames from 'classnames';
import queryString from 'query-string';
import SHPDocumentVersions from './SHPDocumentVersions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManageDocumentHistory from './ManageDocumentHistory';
import { Link as Link } from "react-router-dom";

const allTabs = [
    {
        label: "All Versions",
        key: "version"
    },
    {
        label: "All Versions History",
        key: "history"
    }
];

export default class SHPDocumentTabs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            documentID:0,
            activeTab: 'version',
            versionCount: 0,
            documentVersion:undefined,
            selectedVersion:undefined,
        };

        this.handleVersionCount = this.handleVersionCount.bind(this);
        this.triggerHistoryRefresh = this.triggerHistoryRefresh.bind(this);
        this.handleChangeVersion = this.handleChangeVersion.bind(this);
    }

    componentDidMount() {
        let { documentID, activeTab, documentVersion } = this.state;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            documentID = searchParams.uuid;
            activeTab = searchParams.activeTab ? searchParams.activeTab : activeTab;
            documentVersion = searchParams.documentVersion ? parseInt(searchParams.documentVersion) : undefined;
            this.setState({ documentID, activeTab, documentVersion});
        }
    }

    componentWillReceiveProps(nextProps) {
        let { documentID, activeTab, documentVersion } = this.state;
        if (nextProps.location.search) {
            let searchParams = queryString.parse(nextProps.location.search);
            if (documentID !== searchParams.documentId) {
                documentID = searchParams.uuid;
                activeTab = searchParams.activeTab ? searchParams.activeTab : activeTab;
                documentVersion = searchParams.documentVersion ? parseInt(searchParams.documentVersion) : undefined;
                this.setState({ documentID, activeTab, documentVersion });
            }
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            if (this.props.location) {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set("activeTab", tab);

                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
            }
        }
    }

    handleVersionCount(data) {
        this.setState({ versionCount: data });
    }

    triggerHistoryRefresh() {
        this.setState({ refreshAgainHistory: true });
    }

    handleChangeVersion(version) {
        this.setState({ selectedVersion: version, activeTab: 'version' });
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("activeTab", 'version');

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    render() {
        let { documentID, activeTab, documentVersion, refreshAgainHistory } = this.state;
        if (allTabs.length === 0) {
            return null;
        }
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/shp/documents">Documents</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Manage</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {allTabs.map((tab, index) =>
                                <NavItem
                                    className={"hoverableItem"}
                                    key={index}>
                                    <NavLink
                                        className={classnames({ active: activeTab === tab.key })}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button size={"sm"} color={"link"} style={{ textDecoration: "none" }}>
                                            {tab.label}&nbsp;&nbsp;
                                            {tab.key === 'version' ? <Badge color='primary'>{this.state.versionCount}</Badge> : null}
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>

                        <TabContent activeTab={activeTab}>
                            <br />
                            <TabPane tabId="version">
                                <SHPDocumentVersions documentID={documentID}
                                    handleVersionCount={this.handleVersionCount}
                                    documentVersion={documentVersion}
                                    triggerHistoryRefresh={this.triggerHistoryRefresh}
                                    location={this.props.location}
                                    history={this.props.history}
                                    selectedVersion={this.state.selectedVersion} />
                            </TabPane>
                            <TabPane tabId="history">
                                <ManageDocumentHistory
                                    documentID={documentID}
                                    refreshAgainHistory={refreshAgainHistory}
                                    handleChangeVersion={this.handleChangeVersion}/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}