import React, {Component} from 'react';

import {Link as Link} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import SweetAlert from 'react-bootstrap-sweetalert';

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SHPUserService from '../../services/SHP/SHPUserService';
import ResetPasswordModal from './ResetPasswordModal';
import SHPRoleList from './SHPRoleListjs';
import SearchSmartHomeCreditor from '../../components/search/SearchSmartHomeCreditor';
import {handleErrorMessage} from "../../services/CommonService";


export default class SHPUserManagePage extends Component {
    static displayName = SHPUserManagePage.name;

    constructor(props) {
        super(props);

        let user;
        let editing = false;
        if (props.location.state.user.userId) {
            editing = true;
            const dbuser = props.location.state.user;
            user = {
                id: dbuser.userId,
                firstname: dbuser.firstName,
                lastname: dbuser.lastName,
                email: dbuser.emailAddress,
                password: '',
                userCategory: dbuser.userCategory,
            }
        } else {
            user = {
                id: '',
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                userCategory:'KEYWAY_SHP_USER'
            }
        }

        this.state = {
            editing: editing,
            user: user,
            saving: false,
            formValid: false,
            formErrors: {},
            newrole: {},
            loading: {
                saveUser: false,
                addRole: false,
                removeRole: false,
                updatePassword: false
            },
            showAlert: false,
            msg: "",
            basicType: "",
            basicTitle: "",
            openResetPasswordModel: false,
            clearResetPasswordModel: false,
            userData: [],
            flag: false,
            userCategories:[],

        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.addRole = this.addRole.bind(this);
        this.removeRole = this.removeRole.bind(this);
        this.refreshUserRoles = this.refreshUserRoles.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.toggleOpenResetPasswordModal = this.toggleOpenResetPasswordModal.bind(this);
        this.toggleClearResetPasswordModal = this.toggleClearResetPasswordModal.bind(this);
        this.activateRole = this.activateRole.bind(this);
        this.setPrivilege = this.setPrivilege.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }

    componentDidMount() {
        this.refreshUserRoles();
        SHPUserService.getUser().then(data => {
            this.setState({userData: data}, () => this.setPrivilege());
        })

        SHPUserService.getUserCategories().then(res => {
            this.setState({ userCategories: res.data });
        })
    }


    setPrivilege() {
        this.setState({ flag: SHPUserService.hasPrivilege(this.state.userData, "shp-artwork-user-write")})
    }

    refreshUserRoles() {
        //if we are editing the user then fetch its existing roles
        if (this.state.editing) {
            SHPUserService.listUserRoles(this.state.user.id)
                .then(response => {
                    this.setState({roles: response.data})
                }).catch(error => {
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
            })
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let user = {...this.state.user};
        user[name] = value;
        this.setState({user: user});
    }

    handleCancel() {
        this.props.history.push('/shp/users')
    }

    handleRoleChange(roleId) {
        let newrole = this.state.newrole;
        newrole.roleId = roleId;
        if (newrole.roleId) {

        }
        this.setState({newrole: newrole})

    }

    validateForm(user, name) {

        let formErrors = {};
        let fnv = user.firstname.trim().length > 1;
        let lnv = user.lastname.trim().length > 1;
        let emv = user.email.trim().length > 3;
        let pwv = this.state.editing ? true : user.password.trim().length > 3;
        let ucv = user.userCategory ? true : false;


        if (!fnv) formErrors.firstname = "Input valid first name";
        if (!lnv) formErrors.lastname = "Input valid last name";
        if (!emv) formErrors.email = "Input valid email";
        if (!pwv) formErrors.password = "Input valid password";
        if (!ucv) formErrors.userCategory = "Select category";

        let result = {valid: true, formErrors: {}};


        result = {valid: fnv && lnv && emv && pwv && ucv, formErrors: formErrors};
        return result;
    }


    activateRole(role) {

        SHPUserService.setPrimaryRole(role.id).then(response => {
            this.refreshUserRoles();
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    async handleSubmit(event) {
        event.preventDefault();

        let result = this.validateForm(this.state.user);
        if (!result.valid) {
            this.setState({formErrors: result.formErrors});
            return;
        }
        const user = {
            id: this.state.user.id,
            firstname: this.state.user.firstname,
            lastname: this.state.user.lastname,
            email: this.state.user.email,
            password: this.state.user.password,
            userCategory: this.state.user.userCategory,
        };

        let loading = this.state.loading;
        loading.saveUser = true;
        this.setState({
            loading: loading,
            formErrors: {}
        });

        SHPUserService.saveUser(user).then(response => {
            let dbuser = response.data;
            if (dbuser) { // Success!

                let user = {
                    id: dbuser.userId,
                    firstname: dbuser.firstName,
                    lastname: dbuser.lastName,
                    email: dbuser.emailAddress,
                    password: '',
                    userCategory: dbuser.userCategory,
                };

                let loading = this.state.loading;
                loading.saveUser = false;

                if (this.state.editing) { // if editing already created user
                    this.setState({
                        user: user,
                        editing: true,
                        loading: loading,
                        showAlert: true,
                        msg: "User updated !",
                        basicTitle: "Success",
                        basicType: "success"
                    })
                } else { // new user
                    this.setState({
                        user: user,
                        editing: true,
                        loading: loading,
                        showAlert: true,
                        msg: "User created, please assign role to this user.",
                        basicTitle: "Success",
                        basicType: "success"
                    })
                }
            }

        }, error => {
            let msg = handleErrorMessage(error);
            let loading = this.state.loading;
            loading.saveUser = false;
            this.setState({showAlert: true, msg: msg, basicTitle: "Error", basicType: "danger", loading: loading})
        });


    }

    closeAlert() {
        this.setState({showAlert: false});
    }


    addRole() {
        let loading = this.state.loading;
        loading.addRole = true;
        this.setState({
            loading: loading
        });
        SHPUserService.addRole(this.state.user.id, this.state.newrole)
            .then(ok => {
                loading = this.state.loading;
                loading.addRole = false;
                this.setState({
                    loading: loading
                });
                this.refreshUserRoles();

            }, error => {
                loading = this.state.loading;
                loading.addRole = false;
                let newrole = this.state.newrole;
                this.setState({
                    loading: loading, newrole: newrole
                });
                    toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });

    }

    removeRole(id) {
        SHPUserService.removeRole(id).then(this.refreshUserRoles)
    }

    updatePassword(password, repassword) {
        let {loading, user} = this.state;

        loading.updatePassword = true;
        this.setState({ loading });
        SHPUserService.updatePasswordByAdmin(password, repassword, user.email).then(response => {
            toast.success("Password updated!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            loading.updatePassword = false;
            this.setState({loading});
            this.toggleOpenResetPasswordModal(false);
        }).catch(error => {
            loading.updatePassword = false;
            this.setState({loading});
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggleOpenResetPasswordModal(change) {
        let {clearResetPasswordModel} = this.state;
        if (!change) {
            clearResetPasswordModel = true;
        }
        this.setState({openResetPasswordModel: change, clearResetPasswordModel});
    }

    toggleClearResetPasswordModal(change) {
        this.setState({clearResetPasswordModel: change});
    }

    handleAccountChange(change, key) {
        let { newrole } = this.state;
        if (change === null) {
            newrole[key] = null;
        }
        else {
            newrole[key] = change.accountID;
        }
        this.setState({ newrole });
    }


    render() {

        let user = this.state.user;
        let roles = this.state.roles;
        let loading = this.state.loading;
        let formErrors = this.state.formErrors;
        let newrole = this.state.newrole;
        let openResetPasswordModel = this.state.openResetPasswordModel;
        let clearResetPasswordModel = this.state.clearResetPasswordModel;

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/shp/users">Users</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.state.editing ? "Update user" : "Add user"}</BreadcrumbItem>
                </Breadcrumb>
                {this.state.showAlert ?
                    <SweetAlert show={this.state.showAlert} type={this.state.basicType} title={this.state.basicTitle}
                                onConfirm={this.closeAlert}> {this.state.msg} </SweetAlert> : null}
                <div>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Card className="card-absolute">
                                <CardHeader>
                                    <Row>
                                        <Col><h5
                                            className={"text-left"}>{this.state.editing ? "Update user" : "Add user"}</h5>
                                        </Col>
                                        {this.state.editing ?
                                            <Col>
                                                <div className={"text-right"}>
                                                    <Button size={"sm"} color={"primary"}
                                                            onClick={() => this.toggleOpenResetPasswordModal(true)}>Change
                                                        Password</Button>
                                                </div>
                                            </Col> : null}

                                    </Row>

                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit} autoComplete="off">
                                        <FormGroup>
                                            <Label for="firstname">First Name*</Label>
                                            <Input type="text" name="firstname" id="firstname"
                                                   value={user.firstname || ''}
                                                   onChange={this.handleChange} autoComplete="off"/>
                                            <FormText color="danger">{formErrors.firstname}</FormText>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="lastname">Last Name*</Label>
                                            <Input type="text" name="lastname" id="lastname"
                                                   value={user.lastname || ''}
                                                   onChange={this.handleChange} autoComplete="off"/>
                                            <FormText color="danger">{formErrors.lastname}</FormText>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Email*</Label>
                                            <Input type="text" name="email" id="email"
                                                   value={user.email || ''}
                                                   disabled={this.state.editing}
                                                   onChange={this.handleChange} autoComplete="off"/>
                                            <FormText color="danger">{formErrors.email}</FormText>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="category">Category*</Label>
                                            <Input type="select" name="userCategory" id="userCategory"
                                                value={user.userCategory || ''}
                                                onChange={this.handleChange}>
                                                {(this.state.userCategories || []).map((category, index) => (
                                                    <option key={index} value={category.name}>{category.description}</option>
                                                ))}
                                            </Input>
                                            <FormText color="danger">{formErrors.userCategory}</FormText>
                                        </FormGroup>

                                        {!this.state.editing ? <FormGroup>
                                            <Label for="password">Password*</Label>
                                            <Input type="password" name="password" id="password"
                                                   value={user.password || ''}
                                                   onChange={this.handleChange} autoComplete="off"/>
                                            <FormText color="danger">{formErrors.password}</FormText>
                                        </FormGroup> : null}

                                        <FormGroup>
                                            {this.state.flag ? <div className="pb-2 text-right">
                                                <Button size="sm" color="secondary"
                                                        onClick={() => this.handleCancel()}>Cancel</Button><span>&nbsp;&nbsp;</span>
                                                <Button size="sm" color="primary"
                                                        type="submit">{this.state.editing ? (this.state.loading.saveUser ? "Updating..." : "Update") : (this.state.loading.saveUser ? "Saving..." : "Save")}</Button>
                                            </div> : null}
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        {
                            user.id
                                ?
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card className="card-absolute">
                                        <CardHeader>
                                            <h5> Manage Role</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <table className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th>Account</th>
                                                        <th>Role</th>
                                                        <th>Active</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {roles && roles.map((role, index) =>
                                                        <tr key={index}>
                                                            <td>{role.account}</td>
                                                            <td>{role.roleName}</td>
                                                            <td><Button size={"sm"}
                                                                outline={!role.isPrimary} color={"success"}
                                                                onClick={() => this.activateRole(role)}>
                                                                <i hidden={!role.isPrimary} className="fa fa-check pr-2"
                                                                    aria-hidden="true" />
                                                                {role.isPrimary ? "Active" : "Activate"}
                                                            </Button></td>
                                                            <td><Button size="sm" color="primary"
                                                                onClick={() => this.removeRole(role.id)}>{this.state.loading.removeRole ? "Removing.." : "Remove"}</Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td colSpan={'2'}>
                                                            <SearchSmartHomeCreditor
                                                                handleAccountChange={(accountID) => this.handleAccountChange(accountID, "account")}
                                                                selectedAccountID={newrole.account}
                                                                defaultAccountID={newrole.account} />


                                                        </td>
                                                        <td>
                                                            <SHPRoleList selectedrole={newrole.roleId}
                                                                roleChangeHandler={this.handleRoleChange} />
                                                        </td>
                                                        <td>
                                                            <Button size="sm" color="primary"
                                                                onClick={this.addRole}>{this.state.loading.addRole ? "Adding.." : "Add"}</Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </CardBody>
                                    </Card>
                                </Col> : null
                        }
                    </Row>
                </div>
                <ResetPasswordModal
                    user={user}
                    isOpen={openResetPasswordModel}
                    toggleModel={this.toggleOpenResetPasswordModal}
                    toggleClear={this.toggleClearResetPasswordModal}
                    updatePassword={this.updatePassword}
                    loading={loading.updatePassword}
                    clear={clearResetPasswordModel}
                />
                <ToastContainer/>
            </div>
        );
    }
}
