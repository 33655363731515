import moment from 'moment';


// Array Utility Methods

export function ifExist(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return true;
        }
    }
    return false;
}

export function findIndex(array, attr, value) {
    if (!array) {
        return -1;
    }
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

export function findItems(array, attr, value) {
    let result = [];
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            result.push(array[i]);
        }
    }
    return result;
}

export function getDistinct(array, attr) {
    let unique = [];
    let distinct = [];
    for (let i = 0; i < array.length; i++) {
        if (!unique[array[i][attr]]) {
            distinct.push(array[i]);
            unique[array[i][attr]] = 1;
        }
    }
    return distinct;
}

export function getDistinctValues(array, attr) {
    let unique = [];
    let distinct = [];
    for (let i = 0; i < array.length; i++) {
        if (!unique[array[i][attr]]) {
            distinct.push(array[i][attr]);
            unique[array[i][attr]] = 1;
        }
    }
    return distinct;
}

export function removeDuplicates(array) {
    let unique = [];
    let distinct = [];
    for (let i = 0; i < array.length; i++) {
        if (!unique[array[i]]) {
            distinct.push(array[i]);
            unique[array[i]] = 1;
        }
    }
    return distinct;
}

export function getSorted(array, attr, ascending) {
    array.sort((a, b) => compare(a, b, attr, ascending));
    return array;
}

export function compare(a, b, attr, ascending) {
    if (a[attr] > b[attr]) {
        if (ascending) {
            return 1;
        } else {
            return -1;
        }
    }
    if (b[attr] > a[attr]) {
        if (ascending) {
            return -1;
        } else {
            return 1;
        }
    }
    return 0;
}

export function isAllSelected(arr, param) {
    if (arr) {
        for (let i = 0; i < arr.length; i++) {
            if (!arr[i][param]) {
                return false;
            }
        }
        return true;
    }
    return false;
}

export function findItemHavingMinValue(arr, param) {
    if (!arr) {
        return -1;
    }
    return Math.min.apply(Math, arr.map(function (o) {
        return o[param];
    }));
}

export function findItemHavingMaxValue(arr, param) {
    if (!arr) {
        return -1;
    }
    return Math.max.apply(Math, arr.map(function (o) {
        return o[param];
    }));
}


// Date Utility Methods
export function isToday(dateString, pattern) {
    const todayDate = moment(new Date()).format(pattern);
    return dateString === todayDate;
}


function sortDates(a, b) {
    return a.getTime() - b.getTime();
}

export function getMinDate(array) {
    // type of array should be date only

    let sorted = array.sort(sortDates);
    return sorted[0];
}

export function getMaxDate(array) {
    // type of array should be date only

    let sorted = array.sort(sortDates);
    return sorted[sorted.length - 1];
}


export function convertUtcToLocalDate(utcDate) {
    let localDate = "";
    if (utcDate) {
        let utcDateObj = moment.utc(utcDate);
        localDate = utcDateObj.local();
        return localDate.format("DD/MM/YYYY");
    }
    return localDate;
}

export function getUtcDate(utcDateString) {
    if (utcDateString) {
        return moment.utc(utcDateString);
    }
}

export function convertUtcDateToLocalDate(utcDateString) {
    let localDate = "";
    if (utcDateString) {
        localDate = getUtcDate(utcDateString).local();
        return localDate;
    }
}

export function getLocalDateStringFromUtcDateString(utcDateString, localDateStringFormat) {
    if (utcDateString) {
        return convertUtcDateToLocalDate(utcDateString).format(localDateStringFormat);
    }
    return "";
}

export function changeFormatOfDateString(dateString, currentFormat, targetFormat) {
    if (dateString) {
        let momentDate = moment(dateString, currentFormat);
        return momentDate.format(targetFormat);
    }
    return "";
}

export function getDateObj(dateString, currentFormat) {
    if (dateString) {
        let momentDate = moment(dateString, currentFormat);
        return momentDate.toDate();
    }
    return null;
}

export function getMomentObj(dateString, currentFormat) {
    if (dateString) {
        return moment(dateString, currentFormat);
    }
    return null;
}

export function getDateString(dateObj, targetFormat) {
    if (dateObj) {
        return moment(dateObj).format(targetFormat);
    }
    return "";
}

export function getMonthList() {
    let now = new Date();
    let monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let months = [];
    monthShortNames.map((item, i) => {
        months.push({
            monthName: item,
            monthValue: i + 1,
            year: now.getFullYear()
        })
    });
    return months;
}

export function getStartEndDateFromRangeText(dateRangeText) {

    let baseDate;
    let from_date;
    let to_date;
    switch (dateRangeText) {
        case  "Today":
            from_date = new Date();
            to_date = from_date;
            break;
        case  "Tomorrow":
            from_date = moment().add(1, 'days').toDate();
            to_date = from_date;
            break;
        case  "This week":
            baseDate = moment();
            from_date = baseDate.startOf('week').toDate();
            to_date = baseDate.endOf('week').toDate();
            break;
        case  "This month":
            baseDate = moment();
            from_date = baseDate.startOf('month').toDate();
            to_date = baseDate.endOf('month').toDate();
            break;
        case  "This quarter":
            baseDate = moment();
            from_date = baseDate.startOf('quarter').toDate();
            to_date = baseDate.endOf('quarter').toDate();
            break;
        case  "This year":
            baseDate = moment();
            from_date = baseDate.startOf('year').toDate();
            to_date = baseDate.endOf('year').toDate();
            break;
        case  "Last week":
            baseDate = moment().subtract(1, 'weeks');
            from_date = baseDate.startOf('week').toDate();
            to_date = baseDate.endOf('week').toDate();
            break;
        case  "Last month":
            baseDate = moment().subtract(1, 'months');
            from_date = baseDate.startOf('month').toDate();
            to_date = baseDate.endOf('month').toDate();
            break;
        case  "Last quarter":
            baseDate = moment().subtract(1, 'quarters');
            from_date = baseDate.startOf('quarter').toDate();
            to_date = baseDate.endOf('quarter').toDate();
            break;
        case  "Last year":
            baseDate = moment().subtract(1, 'years');
            from_date = baseDate.startOf('year').toDate();
            to_date = baseDate.endOf('year').toDate();
            break;
    }
    return {
        startDate: from_date,
        endDate: to_date
    }
}


//Other Utilities

export function getFullName(firstName, lastName) {
    let fullName = "";
    if (firstName) {
        fullName = fullName + firstName;
    }
    if (lastName) {
        fullName = fullName + " " + lastName;
    }
    return fullName;
}


export function isEmpty(param, type) {
    switch (type) {
        case "primitive":
            return !param;

        default :
            if (param) {
                for (let x in param) {
                    return false;
                }
            }
            return true;
    }
}

export function scrollTo(element) {
    if (element) {
        switch (element) {
            case "top":
                window.scrollTo(0, 0);
                break;
            default:
                if (document.getElementById(element)) {
                    document.getElementById(element).scrollIntoView({
                        behavior: 'smooth'
                    });
                }
        }
    }
}

export function getDeliveryAddressString(item) {
    return (item.delAddr1 ? item.delAddr1 + ", " : "")
        + (item.delAddr2 ? item.delAddr2 + ", " : "")
        + (item.delAddr3 ? item.delAddr3 + ", " : "")
        + (item.delAddr4 ? item.delAddr4 + ", " : "")
        + (item.delCity ? item.delCity + ", " : "")
        + (item.delState ? item.delState + ", " : "")
        + (item.delPostCode ? item.delPostCode : "");
}

export function getParentCompanyAddress(item) {
    return (item.p_Addr1 ? item.p_Addr1 + ", " : "")
        + (item.p_Addr2 ? item.p_Addr2 + ", " : "")
        + (item.p_Addr3 ? item.p_Addr3 + ", " : "")
        + (item.p_Addr4 ? item.p_Addr4 + ", " : "")
        + (item.p_City ? item.p_City + ", " : "")
        + (item.p_State ? item.p_State + ", " : "")
        + (item.p_PostCode ? item.p_PostCode : "");
}

export function getCommonAddressString(item) {
    return (item.address1 ? item.address1 + ", " : "")
        + (item.address2 ? item.address2 + ", " : "")
        + (item.address3 ? item.address3 + ", " : "")
        + (item.address4 ? item.address4 + ", " : "")
        + (item.city ? item.city + ", " : "")
        + (item.state ? item.state + ", " : "")
        + (item.postCode ? item.postCode : "");
}

export function getColors() {
    return [
        '#e83e8c',
        '#dc3545',
        '#007bff',
        '#6610f2',
        '#fd7e14',
        '#ffc107',
        '#28a745',
        '#20c997',
        '#17a2b8',
        '#6c757d',
        '#343a40',
        '#6f42c1',];
}

export function colorConvertHexToRgb(hex) {
    let rgbString = null;
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let rgb = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
    if (rgb) {
        rgbString = 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')';
    }
    return rgbString;

}

export function numberWithThousandSeparator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
    }, {});
}


export function handleErrorMessage(error) {
    if (error.response) {
        if (error.response.data) {
            if (error.response.data.Message) {
                return error.response.data.Message;
            }
            else {
                return "Something went wrong!";
            }
        }
        else {
            return "Something went wrong!";
        }
    }
    else if (error.Message) {
        return error.Message;
    }
    else if (!error.message && !error.response) {
        return "Something went wrong!";
    }
}








