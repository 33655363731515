import React, { Component } from 'react';
import SHPUserService from '../../services/SHP/SHPUserService';

export default class SHPRoleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dropdownOpen: false,
            selectedRole: this.props.selectedrole
        };

        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        SHPUserService.listRoles().then(response => {
            this.setState({ roles: response.data, loading: false });
        });
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleChange(e) {
        this.setState({ selectedRole: e.target.value });
        this.props.roleChangeHandler(e.target.value)
    }

    render() {

        if (this.state.loading) return (<div>please wait...</div>);
        var roles = this.state.roles;

        return (

            <select className="form-control" value={this.state.selectedRole} onChange={this.handleChange}>
                <option value="0">Choose...</option>
                {roles.map(role =>
                    <option key={role.roleID} value={role.roleID}>{role.name}</option>
                )}
            </select>

        )
    }
}