import React, { Component } from 'react';
import Notification from './notification.component'
import UserMenuDropdown from './UserMenuDropdown.component'
import { Link } from "react-router-dom";

export default class HeaderContent extends Component {

    constructor(props) {
        super(props);
    }

    isCustomerUser(user) {
        if (user && user.menu && (user.menu.role === "ExternalAdmin" || user.menu.role === "ExternalUser")) {
            return true;
        } else return false;
    }

    render() {

        if (this.props.isLoggedIn) {
            return (
                <span style={{ verticalAlign: "middle" }}>
                    {this.isCustomerUser(this.props.user)
                        ? null
                        : <span style={{ float: "left" }}>
                            <a style={{ verticalAlign: "middle" }}
                                onClick={this.props.toggleOpen}
                                href="#"
                            >
                                <i className="fa fa-bars fa-lg" aria-hidden="true" />
                            </a>
                        </span>
                    }

                    {this.props.docked
                        ? null
                        : <span className="pl-2" style={{ float: "left" }}>
                            <Link to={"/"}> <img
                                className="img-fluid"
                                style={{ height: "55px" }}
                                alt=""
                                src="https://winonline.blob.core.windows.net/shp/img/logo/shp-logo.PNG" />
                            </Link>
                        </span>
                    }


                    <span className={"d-inline-block "}>
                        <UserMenuDropdown user={this.props.user} />
                    </span>
                    <span className={"d-inline-block"}>
                        <Notification />
                    </span>

                </span>
            );
        }
        return null;


    }
}

