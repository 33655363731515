import React, {Component} from 'react';
import RoutesConfig from './config/routes.config';
import { ClearCacheProvider } from 'react-clear-cache';

import Layout from './common/Layout';


export default class App extends Component {

    render() {

        return (
            <ClearCacheProvider duration={300000} auto={true}>
            <div className="App">
                <Layout history={this.props.history}>
                    <RoutesConfig/>
                </Layout>
                </div>
            </ClearCacheProvider>
        );
    }
}