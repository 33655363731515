import { config } from '../../helpers/config'
import axios from '../axios';
import axiosFileDownloader from '../axiosFileDownloader';

class SHPDocumentsService {
    static Instance() {
        return new SHPDocumentsService()
    }

    getDocument(attachmentId) {
        return axios.get('api/shp/document/get/' + attachmentId);
    }

    listDocuments(req) {
        return axios.post('api/shp/document/list', req);
    }

    saveDocument(request, options) {
        return axios.post("api/shp/document/save", request);
    }

    updateDocument(request, options) {
        return axios.post("api/shp/document/update", request);
    }

    getDocumentHistory(attachmentID) {
        return axios.get('api/shp/document/history/' + attachmentID);
    }

    DownloadAttachment(id, documentID, options) {
        if (options && options.onDownloadProgress) {
            return axiosFileDownloader.get('api/shp/document/file/generate?AttachmentID=' + id + '&SHPDocumentID=' + documentID, options);
        } else {
            return axiosFileDownloader.get('api/shp/document/file/generate?AttachmentID=' + id + '&SHPDocumentID=' + documentID);
        }
    }

    generateFileFromAttachmentHistory(attachmentHistoryID, options) {
        if (options && options.onDownloadProgress) {
            return axiosFileDownloader.get('api/shp/document/history/file/generate/' + attachmentHistoryID, options);
        }
        else {
            return axiosFileDownloader.get('api/shp/document/history/file/generate/' + attachmentHistoryID);
        }
    }

    uploadDocsofSHPAttachment(requestBody, onUploadProgress) {
        let user = JSON.parse(localStorage.getItem('user'));
        const request = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + user.token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            url: config.apiUrl + "api/shp/document/upload",
            data: requestBody,
            onUploadProgress: onUploadProgress
        };
        return axios(request);
    }

    deleteSHPDocument(ID, documentID) {
        let url = 'api/shp/document/upload/delete?AttachmentId=' + ID;
        if (documentID) {
            url = 'api/shp/document/upload/delete?AttachmentId=' + ID + '&SHPDocumentID=' + documentID;
        }
        return axios.get(url);
    }

    getTags() {
        return axios.get('api/shp/document/tags');
    }

    getHistory(request) {
        return axios.post('api/shp/document/history/list', request);
    }

    getHistoryForParticularDocument(request) {
        return axios.post('api/shp/document/history/one', request);
    }

    getDocumentWithVersionDetails(documentID) {
        return axios.get('api/shp/document/version/details?SHPDocumentID=' + documentID);
    }

    getHistoryForParticularVersionOfDocument(request) {
        return axios.post('api/shp/document/history/version', request);
    }

    DownloadZip(attachmentIDs, documentID) {
        return axios.get('api/shp/document/file/generatezip?AttachmentIDs=' + attachmentIDs + '&SHPDocumentID=' + documentID);
    }

    getFinelineItemsDetails(req) {
        return axios.post('api/shp/document/fineline-items/details', req);
    }

    deleteDocument( documentID) {
        return axios.delete('api/shp/document/delete?SHPDocumentID=' + documentID);
    }

}

export default SHPDocumentsService.Instance()