import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
//Pages
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/auth/LoginPage'
import UserStyledInbox from '../pages/user/UserInbox';
import ManageUserInboxTemplate from '../pages/user/ManageUserInboxTemplate';
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
//Components
import CustomerProfile from '../components/user/UserProfile';
import ManageDocument from '../components/shp/ManageDocument';
import SHPUserManagePage from '../pages/shp/SHPUserManagePage';
import SHPUserPage from '../pages/shp/SHPUserPage';
import SHPDocuments from '../components/shp/SHPDocuments';
import SHPDocumentHistory from '../components/shp/SHPDocumentHistory';
import ManageDocumentHistory from '../components/shp/ManageDocumentHistory';
import SHPDocumentTabs from '../components/shp/SHPDocumentTabs';
import SHPCommonDocumentHistory from '../components/shp/SHPCommonDocumentHistory';

const RoutesConfig = props => {

    return (
        <div>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/forgot" component={ForgotPasswordPage} />

            <PrivateRoute exact path="/" component={HomePage} />
            <PrivateRoute path='/profile' component={CustomerProfile} />
            <PrivateRoute exact path='/user/inbox' component={UserStyledInbox} />


            <PrivateRoute exact path='/manage/user-inbox/template' component={ManageUserInboxTemplate} />
            <PrivateRoute exact path='/shp/documents' component={SHPDocuments} />
            <PrivateRoute exact path='/shp/create/document' component={ManageDocument} />
            <PrivateRoute exact path='/shp/edit/document' component={SHPDocumentTabs} />
            <PrivateRoute exact path='/shp/users' component={SHPUserPage} />
            <PrivateRoute exact path='/shp/users/manage' component={SHPUserManagePage} />
            <PrivateRoute exact path='/shp/document/history/old' component={SHPDocumentHistory} />
            <PrivateRoute exact path='/shp/document/history' component={ManageDocumentHistory} />
            <PrivateRoute exact path='/shp/document/version/history' component={SHPCommonDocumentHistory} />

        </div>
    );
};

export default RoutesConfig;