import React, { Component } from 'react';
import { Row, Col, Progress, Table } from 'reactstrap';
import FileSaver from 'file-saver';
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from 'react-toastify';
import AttachmentService from "../../services/AttachmentService";


export default class AttachmentComponent extends Component {
    constructor(props) {
        super(props);

        this.AttachmentService = new AttachmentService();

        this.state = {
            AttachmentIds: props.ids,
            Attchment_list: [],
            ProgressValue: 1,
            intervalId: 0,
            selectdId: 0
        };
        this.refresh = this.refresh.bind(this);
        this.fetchAttachments = this.fetchAttachments.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.hideProgressBar = this.hideProgressBar.bind(this);
        this.timer = this.timer.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }
    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ids) {
            if (this.state.AttachmentIds !== nextProps.ids) {
                this.fetchAttachments(nextProps.ids);
            }
        }
    }

    refresh() {
        this.fetchAttachments(this.state.AttachmentIds);
    }



    fetchAttachments(IDs) {
        this.setState({ AttachmentIds: IDs });
        this.AttachmentService.getparticularAttachments(IDs).then(response => {
            //console.log(response, '___--response');
            if (response.status == 200 || response.status == "200") {
                this.setState({ Attchment_list: response.data });
            }
        }).catch(error => {
            console.log(error.data)
        });
    }

    timer() {
        this.setState({
            ProgressValue: this.state.ProgressValue + 10
        }, () => {
            if (this.state.ProgressValue >= 100) {
                clearInterval(this.state.intervalId);
            }
        });
    }

    startProgress() {
        this.setState({ ProgressValue: 2 });
        let intervalId = setInterval(this.timer, 500);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervalId: intervalId });
    }

    downloadFile(item) {
        //console.log(item, 'attachment');
        this.startProgress();
        this.setState({ selectdId: item.id });
        this.AttachmentService.DownloadAttachment(item.id).then(response => {
            this.setState({ ProgressValue: 100 });
            clearInterval(this.state.intervalId);
            FileSaver.saveAs(response.data, item.description);
        }).catch(error => {
            this.hideProgressBar();
            toast.error(JSON.stringify(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });

            console.log(error);
        });
    }

    hideProgressBar() {
        this.setState({ ProgressValue: 0, selectdId: 0 });
        clearInterval(this.state.intervalId);
    }

    render() {
        let { Attchment_list, ProgressValue, selectdId } = this.state;
        return (
            <div>
                <Table responsive={true} striped={false} borderless={true} bordered={false} hover={true} style={{ cursor: 'pointer' }}>
                    <tbody>
                        {
                            (Attchment_list || []).map((item, index) => {
                                return <tr key={index} onClick={() => this.downloadFile(item)}>
                                    <td>
                                        <i className="fa fa-paperclip fa-fw text-muted mr-2" aria-hidden="true" />
                                        {item.description}
                                    </td>
                                    <td className="text-right">
                                        {selectdId == item.id ?
                                            <div className="mt-1">
                                                <Progress value={ProgressValue} style={{ minWidth: 75 }} />
                                            </div>
                                            : null
                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
                <ToastContainer />
            </div>
        );
    }
}