import React from "react";
import PropTypes from "prop-types";

const styles = {
    sidebarHeader: {
        padding: "",
    },
    contentHeader: {
        backgroundColor: "white",
        padding: "12px",
        fontSize: "1.15em",
        textAlign: "right",
        color: "black"
    }

};

const Header = props => {
    const rootStyle = props.style
        ? {...styles.root, ...props.style}
        : styles.root;

    let headerStyle = {};
    if (props.isLoggedIn) {
        headerStyle = styles.sidebarHeader;
        if (props.type === "content") {
            headerStyle = styles.contentHeader;
        }
    }

    return (
        <div style={rootStyle}>
            <div style={headerStyle}><span>{props.title}</span></div>
            {props.children}
        </div>
    );
};

Header.propTypes = {
    style: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.object
};

export default Header;
