import React, { Component } from 'react';
import Dashboard from "../components/dashboard/Dashboard";
import queryString from 'query-string';

export default class HomePage extends Component {
    static displayName = HomePage.name;

    componentDidMount() {
        let history = this.props.history;
        let location = history.location;
        let pathname = location.pathname;

        if (location.search) {
            let searchParams = queryString.parse(location.search);
            if (searchParams.link) {
                pathname = pathname + searchParams.link;
            }
            if (searchParams.uuid) {
                pathname = pathname + '?uuid=' + searchParams.uuid;
            }

            this.props.history.push(pathname);
        }
    }

    render() {
    return (
       <Dashboard {...this.props} />
    );
  }
}
