import {authHeader} from '../helpers/auth-header';
import {config, headers} from '../helpers/config'
import axios from './axios';

export function handleResponse(response) {
    return new Promise((resolve, reject) => {
        var contentType = response.headers.get("content-type");
        if (response.ok) {
            // return json if it was returned in the response

            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve("success");
            }
        } else {

            if (contentType && contentType.includes("application/json")) {
                // return error message from response body
                response.json().then(json => reject(json));
            } else {
                response.text().then(text => reject(text));
            }
        }
    });
}

export function handleError(error) {
    return Promise.reject(error && error.message);
}

export default class UserService {

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
    }

    async checkLogin() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.token) return false;
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        return await fetch(config.apiUrl + 'api/user/', requestOptions);
    }

    static isLoggedIn() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            return true;
        }
        return false;
    }

    isCustomerUser(user) {
        if (user && user.menu && (user.menu.role === "ExternalAdmin" || user.menu.role === "ExternalUser")) {
            return true;
        }
        return false;
    }

    login(username, password) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({username, password})
        };

        return fetch(config.apiUrl + 'api/user/authenticate', requestOptions)
            .then(handleResponse, handleError)
            .then(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                }
                return user;
            });
    }

    resetPassword(username) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({username})
        };

        return fetch(config.apiUrl + 'api/user/reset', requestOptions).then(handleResponse, handleError)
    }

    updatePasswordByReset(password, repassword, token) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({password, repassword, token})
        };
        return fetch(config.apiUrl + 'api/user/updatePassword', requestOptions)
            .then(handleResponse, handleError)
            .then(user => {
                // password updated successfully, if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                }

                return user;
            });
    }

    updatePasswordByAdmin(password, repassword, email) {
        let requestBody = {
            password: password,
            repassword: repassword,
            token: email
        };
        return axios.put('api/admin/user/update/password', requestBody);
    }


    listUsers() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(config.apiUrl + 'api/admin/user/', requestOptions).then(handleResponse, handleError);
    }

    async getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getLoggedInUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getLoggedInUserFromDb(isNewTokenRequired) {
        let url = 'api/user/self';
        if (isNewTokenRequired) {
            url = 'api/user/self/auth'
        }
        return axios.get(url)
    }

    refreshLocalStorage() {
        this.getLoggedInUserFromDb(true).then(userResponse => {
            let user = userResponse.data;
            localStorage.clear();
            if (user) {
                localStorage.setItem('user', JSON.stringify(user));
                window.location.reload();
            }
        }, error => {
            console.log(error);
        });
    }

    hasPrivilege(user, privilege) {
        if (user) {
            return user.menu.privileges.includes(privilege);
        }
        return false;
    }

    saveUser(user) {
        if (user.id) {
            return axios.put('api/admin/user/', user)
        } else {
            return axios.post('api/admin/user/', user)
        }
    }

    listRoles() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        return fetch(config.apiUrl + 'api/admin/role/list', requestOptions).then(handleResponse, handleError);
    }

    listUserRoles(id) {

        let url = 'api/admin/user/' + id + '/role'
        return axios.get(url)
    }

    addRole(id, newrole) {
        newrole.userId = id;
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(newrole),
        };
        return fetch(config.apiUrl + 'api/admin/user/addrole', requestOptions).then(handleResponse, handleError);
    }

    setPrimaryRole(userRoleId) {
        let url = 'api/admin/user/role/primary?userRoleId=' + userRoleId;
        return axios.post(url);
    }

    removeRole(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader(),
        };
        return fetch(config.apiUrl + 'api/admin/user/removerole/' + id, requestOptions).then(handleResponse, handleError);
    }

    getUsersStat() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        return fetch(config.apiUrl + 'api/dashboard/user/stats', requestOptions).then(handleResponse, handleError);
    }

    deleteUser(id) {
        const requestOptions = {
            method: 'PUT',
            headers: authHeader(),
        };

        return fetch(config.apiUrl + 'api/admin/user/deleteuser/' + id, requestOptions).then(handleResponse, handleError);
    }

    checkUsernameAvailibility(username) {
        let url = 'api/user/check/username/available';
        return axios.post(url, {username})
    }

    searchUser(query, role) {
        let url = "";
        switch (role) {
            case "sales":
                url = 'api/admin/user/sales/search';
                break;
            default:
                url = 'api/admin/user/search';
                break;
        }

        return axios.post(url, {query: query});
    }

    findUsers(ids, usernames, emails) {
        let request = {
            ids: ids,
            usernames: usernames,
            emails: emails
        };
        return axios.post('api/admin/user/list/search/in', request);
    }
}