import React, {Component} from 'react';
import Sidebar from "react-sidebar";

import {EventBus} from "../components/events/event"

import Header from "../common/header/header.component";
import HeaderContent from '../common/header/header.content';
import SidebarContent from "../common/sidebar/sidebar.content";
import UserService from "../services/UserService";


const mql = window.matchMedia(`(min-width: 800px)`);

export default class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            docked: mql.matches,
            open: false,
            user: {}
        };

        this.userService = new UserService();

        this.onSetOpen = this.onSetOpen.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);

        EventBus.subscribe('login-logout', (event) => this.refreshMenu(event));
    }

    componentDidMount() {
        this.refreshMenu();
    }

    componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    onSetOpen(open) {
        this.setState({open});
    }

    mediaQueryChanged() {

        let {docked, user} = this.state;
        docked = mql.matches;
        if (this.userService.isCustomerUser(user)) {
            docked = false;
        }
        this.setState({docked, open: false});
    }

    toggleOpen(ev) {
        let {docked, open, user} = this.state;
        if (mql.matches) {
            docked = !docked;
            if (this.userService.isCustomerUser(user)) {
                docked = false;
            }
            this.setState({docked});
        } else {
            this.setState({open: !open});
        }
        if (ev) {
            ev.preventDefault();
        }
    }

    refreshMenu() {
        let loggedIn = false;
        let {docked} = this.state;
        let self = this;
        this.userService.checkLogin().then(response => {
            if (response) {
                if (response.ok) {
                    this.userService.getLoggedInUserFromDb(true).then(userResponse => {
                        let user = userResponse.data;
                        if (user) {
                            loggedIn = response.ok;
                            localStorage.setItem('user', JSON.stringify(user));
                            if (this.userService.isCustomerUser(user)) {
                                docked = false;
                            }
                            this.setState({user: user, loggedIn: loggedIn, docked});
                            return;
                        } else {
                            localStorage.clear();
                            this.setState({user: {}, loggedIn: loggedIn}, () => {
                                self.props.history.push("/login");
                            });
                            return;
                        }
                    }, error => {
                        console.log(error);
                        localStorage.clear();
                        this.setState({user: {}, loggedIn: loggedIn}, () => {
                            self.props.history.push("/login");
                        });
                        return;
                    });
                } else {
                    localStorage.clear();
                    this.setState({user: {}, loggedIn: loggedIn}, () => {
                        self.props.history.push("/login");
                    });
                    return;
                }
            } else {
                localStorage.clear();
                this.setState({user: {}, loggedIn: loggedIn}, () => {
                    self.props.history.push("/login");
                });
                return;
            }
        })
    }

    render() {
        const isLoggedIn = this.state.loggedIn;

        const contentSideBar = <SidebarContent isLoggedIn={isLoggedIn}
                                               docked={this.state.docked}
                                               user={this.state.user}/>;
        const contentHeadbar = <HeaderContent isLoggedIn={isLoggedIn}
                                              user={this.state.user}
                                              docked={this.state.docked}
                                              toggleOpen={this.toggleOpen}/>;

        const sidebarProps = {
            sidebar: contentSideBar,
            docked: this.state.docked,
            open: this.state.open,
            onSetOpen: this.onSetOpen,
            styles: {
                sidebar: {
                    backgroundColor: "white"
                },
                content: {
                    backgroundColor: "#f0f2f5"
                }
            }
        };

        return (
            <div>
                <Sidebar {...sidebarProps}>
                    <Header title={contentHeadbar} type={"content"} isLoggedIn={isLoggedIn}/>
                    <div className={"mainContentBody"}>
                        {this.props.children}
                    </div>
                </Sidebar>
            </div>
        );
    }
}
