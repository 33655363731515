import React, { Component } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Spinner,
    Table,
    Tooltip,

    Badge
} from 'reactstrap';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import SHPDocumentsService from '../../services/SHP/SHPDocumentsService';
import { getLocalDateStringFromUtcDateString,handleErrorMessage } from '../../services/CommonService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FileSaver from 'file-saver';

export default class SHPDocumentHistoryModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            documentHistory: [],
            loading: {
                documentHistoryList: false
            },
        };

        this.onDownloadDocument = this.onDownloadDocument.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.documentHistory !== nextProps.documentHistory) {
            this.setState({ documentHistory: nextProps.documentHistory });
        }
        if (this.state.loading !== nextProps.loading) {
            this.setState({ loading: nextProps.loading });
        }
        
    }

    getDate(date) {
        if (date) {
            let selectedDate = getLocalDateStringFromUtcDateString(date, "DD/MM/YYYY hh:mm A");

            return selectedDate;
        }
    }

    toggle = targetName => {
        if (!this.state[targetName]) {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: true
                }
            });
        } else {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: !this.state[targetName].tooltipOpen
                }
            });
        }
    };

    isToolTipOpen = targetName => {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    };

    getBadgeColor(event) {
        let result = "";
        switch (event) {
            case "DELETE":
                result = "danger";
                break;
            case "UPLOAD":
                result = "primary";
                break;
            case "REPLACE":
                result = "warning";
                break;
            case "DOWNLOAD":
                result = "success";
                break;


        }

        return result;
    }

    onDownloadDocument(item) {
        item.downloading = true;
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    this.setState({ requestProgressPercentage: percent });
                }
            }
        };
        this.setState({ downloading: true });
        SHPDocumentsService.generateFileFromAttachmentHistory(item.id).then(response => {
            FileSaver.saveAs(response.data, item.description);
            item.downloading = false;
            this.setState({ downloading: false });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            item.downloading = false;
            this.setState({ downloading: false });
        });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { documentHistory,  loading } = this.state;
        
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Document History
                </ModalHeader>
                <ModalBody>
                    {loading.documentHistoryList
                        ? <Spinner color={"primary"} />
                        : <div>
                            <Table responsive={true} hover={true} striped={true}>
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th colSpan={2}>Description</th>
                                        <th>FileName</th>
                                        <th>Initiated By</th>
                                        <th>Initiated On</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(documentHistory || []).map((item, index) => {
                                        return <tr key={index}
                                            className={"hand-cursor"}
                                        >
                                            <td><Badge color={this.getBadgeColor(item.event)}>{item.event}</Badge></td>
                                            <td colSpan={2}><ReactQuill
                                                value={item.description
                                                }
                                                readOnly={true}
                                                theme={"bubble"}
                                            /></td>
                                            <td>{item.fileName}</td>
                                            <td id={`td-${index}`}>
                                                <Tooltip placement="right" isOpen={this.isToolTipOpen(`td-${index}`)}
                                                    target={`td-${index}`} toggle={() => this.toggle(`td-${index}`)}>
                                                    {item.createdByUser.emailAddress}
                                                </Tooltip>
                                                {item.createdByUser.name}</td>
                                            <td>{this.getDate(item.createdOn)}</td>
                                            <td>
                                                <Button color="primary"
                                                    block={true}
                                                    outline={true}
                                                    title="Click here to download"
                                                    className="mt-2"
                                                    disabled={this.state.downloading}
                                                    onClick={() => this.onDownloadDocument(item)}>
                                                    {item.downloading
                                                        ? <Spinner size="sm"
                                                            className={"mr-2"}
                                                            color={"primary"} />
                                                        : <i className="fa fa-download mr-2" />
                                                    }
                                                    Download
                                                    </Button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)}>Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}