import React, {Component} from 'react';
import {Button, Col, Input, ListGroup, ListGroupItem, Progress, Row, Spinner} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import AttachmentService from '../../services/AttachmentService';
import {findIndex, handleErrorMessage} from "../../services/CommonService";
import FileSaver from 'file-saver';
import SHPDocumentsService from '../../services/SHP/SHPDocumentsService';


export default class UploadFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attachmentIDs: "",
            attachments: [],
            uploadProgress: 0,
            downloadProgress: 0,
            loadingAttachments: false,
            isZipping: false
        };

        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleFileDownload = this.handleFileDownload.bind(this);
        this.handleDownloadProgress = this.handleDownloadProgress.bind(this);
        this.handleUploadProgress = this.handleUploadProgress.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
        this.fetchAttachments = this.fetchAttachments.bind(this);
        this.handleZipDownload = this.handleZipDownload.bind(this);
        this.attachmentService = new AttachmentService();
    }

    componentDidMount() {
        if (this.props.attachmentIDs) {
            this.setState({attachmentIDs: this.props.attachmentIDs}, () => {
                if (this.props.attachmentIDs) {
                    this.fetchAttachments(this.props.attachmentIDs);
                }
            });
        } else {
            this.setState({attachmentIDs: "", attachments: []});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.attachmentIDs !== nextProps.attachmentIDs) {
            if (nextProps.attachmentIDs) {
                this.setState({attachmentIDs: nextProps.attachmentIDs}, () => {
                    if (nextProps.attachmentIDs) {
                        this.fetchAttachments(nextProps.attachmentIDs);
                    }
                });
            } else {
                this.setState({attachmentIDs: "", attachments: []});
            }
        }
    }

    fetchAttachments(IDs) {
        this.setState({loadingAttachments: true});
        this.attachmentService.getparticularAttachments(IDs).then(response => {
            if (response.data) {
                this.setState({attachments: response.data, loadingAttachments: false});
            }
        }).catch(error => {
            console.log(error.data);
            this.setState({loadingAttachments: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleUploadProgress(progressEvent) {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
            this.setState({uploadProgress: percent});
        }
    }

    handleFileUpload(file) {
        this.setState({isUploading: true});
        let data = new FormData();
        data.append('files', file);
        data.append('SHPDocumentID', this.props.documentID);

        SHPDocumentsService.uploadDocsofSHPAttachment(data, this.handleUploadProgress).then(response => {
            if (response.data) {
                let element = document.getElementById("fileInputId");
                if (element) {
                    element.value = "";
                }
                let {attachments, attachmentIDs} = this.state;
                let index = -1;
                response.data.forEach(uf => {
                    index = findIndex(attachments, 'id', uf.id);
                    if (index === -1) { // duplicity check
                        attachments.push(uf);
                    }
                });
                let attachmentsIDsArr = attachments.map(a => a.id);
                attachmentIDs = attachmentsIDsArr.join();
                this.setState({attachments, attachmentIDs, uploadProgress: 0}, () => {
                    this.props.handleChange(attachmentIDs);
                });
            }
        }).catch(error => {
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({uploadProgress: 0});
        });
    }

    handleDownloadProgress(progressEvent, attachmentId) {
        let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        let {attachments} = this.state;
        let index = findIndex(attachments, 'id', attachmentId);
        attachments[index].downloadProgress = percent;
        this.setState({attachments});
    }

    handleFileDownload(item) {
        let options = {
            onDownloadProgress: (progressEvent) => this.handleDownloadProgress(progressEvent, item.id)
        };
        SHPDocumentsService.DownloadAttachment(item.id, this.props.documentID, options).then(response => {
            FileSaver.saveAs(response.data, item.description);
            let {attachments} = this.state;
            let index = findIndex(attachments, 'id', item.id);
            attachments[index].downloadProgress = 0;
            this.setState({attachments});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            let {attachments} = this.state;
            let index = findIndex(attachments, 'id', item.id);
            attachments[index].downloadProgress = 0;
            this.setState({attachments});
        });
    }

    handleZipDownload() {
        let {attachmentIDs} = this.state;
        this.setState({isZipping: true});
        SHPDocumentsService.DownloadZip(attachmentIDs, this.props.documentID).then(response => {
            this.setState({isZipping: false});
            window.open(response.data);
        }).catch(error => {
            this.setState({isZipping: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
        });
    }

    handleFileChange(event) {
        let file = event.target.files[0];
        if (file) {
            this.handleFileUpload(file, event);
        }
    }


    deleteHandler(attachmentID) {
        let {attachments, attachmentIDs} = this.state;
        let index = findIndex(attachments, 'id', attachmentID);
        if (index > -1) {
            attachments[index].loadingDelete = true;
        }
        this.setState({attachments});
        index = findIndex(attachments, 'id', attachmentID);
        if (index > -1) {
            attachments.splice(index, 1);
        }
        let attachmentsIDsArr = attachments.map(a => a.id);
        attachmentIDs = attachmentsIDsArr.join();
        this.setState({attachments, attachmentIDs});
        this.props.handleChange(attachmentIDs);

        toast.success("Deleted", {
            position: toast.POSITION.BOTTOM_CENTER
        });
    }

    render() {
        let {
            attachments,
            uploadProgress,
            loadingAttachments,
            isZipping
        } = this.state;
        let {isReadOnly, isDownloadAllFilesBtnVisible} = this.props;
        return (
            <div>
                {
                    isReadOnly
                        ? null
                        : <div>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p>Choose file to upload</p>
                                    <div>
                                        <div className="text-center">
                                            <Input type="file"
                                                   name="attachmentFile"
                                                   id={"fileInputId"}
                                                   onChange={this.handleFileChange}
                                                   aria-label="choose file"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="mt-1">
                                {
                                    uploadProgress > 0
                                        ? <div className={"mt-1"}>
                                            {uploadProgress}%&nbsp;Uploaded
                                            <Progress animated value={uploadProgress}/>
                                        </div>

                                        : null
                                }
                            </div>
                        </div>
                }

                {
                    loadingAttachments
                        ? <Spinner color={"primary"}/>
                        : <ListGroup flush>
                            {(attachments || []).map((a, index) => {
                                return <ListGroupItem className={"hoverableItem"} key={index}>
                                    <Row className={"align-items-center"}>
                                        <Col xl={{size: 'auto'}}
                                             lg={{size: 'auto'}}
                                             md={{size: 'auto'}}
                                             sm={{size: 'auto'}}
                                             xs={{size: 'auto'}}
                                             className={"pl-0"}>
                                            <Button color="link" size={"sm"}
                                                    title={"Click here to download"}
                                                    onClick={() => this.handleFileDownload(a)}>
                                                <i className="fa fa-paperclip mr-1" aria-hidden="true"/> {a.description}
                                                {
                                                    isReadOnly
                                                        ? null
                                                        : <i className="fa fa-check ml-1 text-success" title={"uploaded"}
                                                             aria-hidden="true"/>
                                                }

                                            </Button>

                                            {
                                                isReadOnly
                                                    ? null
                                                    : <Button color="link"
                                                              size={"sm"}
                                                              className={"ml-2"}
                                                              title={"Click here to remove this file"}
                                                              disabled={a.loadingDelete}
                                                              onClick={() => this.deleteHandler(a.id)}>
                                                        {
                                                            a.loadingDelete
                                                                ? <Spinner color={"danger"} size={"sm"}/>
                                                                :
                                                                <i className="fa fa-trash text-danger" aria-hidden="true"/>
                                                        }
                                                    </Button>
                                            }
                                        </Col>
                                        {
                                            (a.downloadProgress > 0 && a.downloadProgress < 100)
                                                ? <Col>
                                                    <Progress animated value={a.downloadProgress}/>
                                                </Col>
                                                : null
                                        }

                                    </Row>

                                </ListGroupItem>
                            })}

                        </ListGroup>
                }

                {
                    (attachments.length > 1 && isDownloadAllFilesBtnVisible)
                        ? <div className="text-right">
                            {isZipping
                                ? <div>
                                    <Spinner size={"sm"} color={"primary"} className={"mr-2"}/>Preparing your download...
                                </div>
                                : <Button size='sm'
                                          color={"link"}
                                          title='click here to download all files in a zipped file'
                                          onClick={this.handleZipDownload}>
                                    <i className="fa fa-file-archive-o mr-2" aria-hidden="true"/>Download all
                                </Button>
                            }
                        </div>
                        : null
                }
                <ToastContainer/>
            </div>
        )
    }
}