import React, {Component} from 'react';
import UserService from "../../services/UserService";
import userInboxService from "../../services/user/UserInboxService";
import {Link} from "react-router-dom";
import {EventBus} from "./../../components/events/event"
import {Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {authHeader} from '../../helpers/auth-header';
import {config} from '../../helpers/config'
import axios from 'axios';
import {handleErrorMessage} from "../../services/CommonService";

class Notification extends Component {

    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.state = {
            notification: {
                records: [],
                totalRecords: 0,
            }
        };

        this.getPendingTasks = this.getPendingTasks.bind(this);
        this.refresh = this.refresh.bind(this);

        EventBus.subscribe('update-notification', (event) => this.refresh());
    }


    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.getPendingTasks();
    }


    getNotifications() {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/dashboard/notification/list'
        };
        return axios(request)
    }

    getPendingTasks() {
        this.getNotifications().then(response => {
            let {notification} = this.state;
            if (response.data) {
                let data = response.data;
                notification.totalRecords = data.totalRecords;
                notification.records = [];
                if (data.records && data.records.length > 0) {
                    for (let i in data.records) {
                        data.records[i].displayText = data.records[i].primaryText ? data.records[i].primaryText : data.records[i].secondaryText;
                        if (data.records[i].displayText && data.records[i].displayText.length > 20) {
                            data.records[i].displayText = data.records[i].displayText.substring(0, 20);
                            data.records[i].displayText = data.records[i].displayText + " ...";
                        }
                        switch (data.records[i].entityName) {
                            case "LeadActivity":
                                data.records[i].to = {
                                    pathname: "/crm/manageLead",
                                    state: {lead: data.records[i]}
                                };
                                break;
                            case "Task":
                                data.records[i].to = {
                                    pathname: "/crm/manageTask/" + data.records[i].id,
                                };
                                break;
                            case "USER_INBOX":
                                data.records[i].to = {
                                    pathname: "/user/inbox",
                                };
                                break;
                        }
                        notification.records.push(data.records[i]);
                    }
                }
                this.setState({notification});
            }
        }).catch(error => {
            alert(handleErrorMessage(error));
        });
    }

    getAvatar(iconText) {

        let icon = "fa fa-envelope-o";
        switch (iconText) {
            case "PHONE":
                icon = "fa fa-phone";
                break;
            case "SMS":
                icon = "fa fa-commenting-o";
                break;
            case "EMAIL":
                icon = "fa fa-envelope-o";
                break;
            case "VISIT":
                icon = "fa fa-map-marker";
                break;
            case "INBOX":
                icon = "fa fa-envelope-o";
                break;
        }
        return <i className={icon} aria-hidden="true"/>
    }


    render() {
        let {notification} = this.state;
        if (notification.totalRecords) {
            return (
                <span>
                    <UncontrolledDropdown nav inNavbar className={"listStyleNone"}>
                        <DropdownToggle nav className={"hoverableItem"}>
                            <i className="fa fa-bell-o " aria-hidden="true"/>
                            <Badge color="primary" className="badge-notify blink"
                                   pill>
                                <small>{notification.totalRecords}</small>
                            </Badge>
                        </DropdownToggle>

                        <DropdownMenu
                            style={{right: "0px",}}
                            right={true}>
                            {(notification.records || []).map((item, index) => {
                                return (
                                    <span key={index}>
                                        <DropdownItem className="overflow-hidden">
                                            <Link style={{textDecoration: "none"}}

                                                  to={item.to}>
                                               <Badge pill
                                                      color={"light"}>{this.getAvatar(item.icon)}</Badge> &nbsp;{item.displayText}
                                            </Link>
                                        </DropdownItem>
                                        <DropdownItem hidden={(notification.records.length - 1) === index}
                                                      divider/>
                                    </span>
                                );
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </span>
            );
        }
        return null;
    }
}

export default Notification