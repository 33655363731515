import React, {Component} from 'react';
import UserService from "../../services/UserService";
import {Row} from "reactstrap";
import SHPDocumentCard from '../../components/shp/SHPDocumentCard';


export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.state = {loading: true, dashboards: []}
    }

    componentDidMount() {

        this.userService.getUser().then(user => {
            let d = user.menu.dashboards.split(",");
            this.setState({loading: false, dashboards: d})
        })
    }

    render() {

        if (this.state.loading) return (<div>Loading...</div>);
        const dashboards = this.state.dashboards;
        return (
            <Row>
                {dashboards.map((dashboard, idx) =>
                    <DynamicComponent tag={dashboard} key={idx}/>
                )}
            </Row>
        );
    }
}

class DynamicComponent extends Component {

    render() {
        const tag = this.props.tag;

        if (tag.trim() === 'SHP-Documents') return <SHPDocumentCard/>;
        //if (tag.trim() === 'RecentlyCreatedTasks') return <RecentlyCreatedTasks />;
        //;
        //  return <div>Unknown {tag}</div>


        return null;
    }
}