import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody,Spinner,
    Table, Row, Col, InputGroup, InputGroupText, InputGroupAddon, Input, Badge, Button,
} from 'reactstrap';
import classnames from 'classnames';
import { findIndex, getLocalDateStringFromUtcDateString, handleErrorMessage } from '../../services/CommonService';
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import SHPDocumentsService from '../../services/SHP/SHPDocumentsService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import SHPUserService from '../../services/SHP/SHPUserService';
import FileSaver from 'file-saver';
import SearchFineLine from '../search/SearchFineLine';
import SearchSmartHomeCreditor from '../search/SearchSmartHomeCreditor';

export default class SHPDocuments extends Component {

    constructor(props) {
        super(props);


        this.state = {
            documents: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "CreatedOn",
                        direction: false
                    },
                    filterRequest: {
                        description: '',
                        fineline: '',
                        subBrand: '',
                        subClass: '',
                        partNumber: '',
                        category: '',
                        supplierCode: '',
                        swishDWDimStockItemID: null
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: false,
            searchText: "",
            isEditingAllowed: false,
            requestProgressPercentage: 0,
            fileTagOptions: [],
            currentUser: {},
            isSupplierUser: false,
        };

        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getDocuments = this.getDocuments.bind(this);
        this.getTags = this.getTags.bind(this);
        this.handleFineLineSelection = this.handleFineLineSelection.bind(this);
    }

    componentDidMount() {
        let { documents } = this.state;
        let currentUser = SHPUserService.getLoggedInUser();
        let isSupplierUser = false;
        if (currentUser.menu && currentUser.menu.role === 'SHP-Supplier') {
            isSupplierUser = true;
            documents.request.filterRequest.supplierCode = currentUser.menu.accountID;
        }

        this.setState({ documents }, () => this.refresh());

        let isEditingAllowed = SHPUserService.hasPrivilege(currentUser, "shp-artwork-attachment-write");
        this.setState({ isEditingAllowed, currentUser, isSupplierUser })
    }

    refresh() {
        let { documents } = this.state;
        this.getDocuments(documents);
        this.getTags();
    }

    getTags() {
        SHPDocumentsService.getTags().then(response => {
            let fileTagOptions = response.data;
            this.setState({ fileTagOptions });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    getDocuments(documents) {
        this.setState({ loading: true });

        let request = cloneDeep(documents.request);
        if (request.filterRequest.startDate != null) {
            request.filterRequest.startDate = moment(request.filterRequest.startDate).format('YYYY/MM/DD');
        }

        if (request.filterRequest.endDate != null) {
            request.filterRequest.endDate = moment(request.filterRequest.endDate).format('YYYY/MM/DD');
        }

        SHPDocumentsService.listDocuments(request).then(response => {
            documents.response = response.data;

            let documentIds = [];
            documents.response.records.forEach(document => {
                documentIds.push(document.id);
                if (document.tags) {
                    let tags = document.tags.split(',');
                    document.tags = tags;
                }
                else {
                    document.tags = [];
                }

                if (document.supplierCodes) {
                    let supplierCodes = document.supplierCodes.split(',');
                    document.supplierCodes = supplierCodes;
                }
                else {
                    document.supplierCodes = [];
                }
            });
            this.setState({ documents, loading: false }, () => this.getFineLineItemsDetails(documentIds));
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error));
        })
    }

    getFineLineItemsDetails(documentIds) {
        let req = {};
        req.shpDocumentIds = documentIds;

        SHPDocumentsService.getFinelineItemsDetails(req).then(res => {
            let finelineItems = res.data;

            let { documents } = this.state;
            documents.response.records.forEach(document => {
                document.finelineItems = finelineItems.filter(f => f.shpDocumentID === document.id);
            });

            this.setState({ documents });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    handleChange(change, key) {
        let { documents } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (documents.request.sortRequest.key === change) {
                    documents.request.sortRequest.direction = !documents.request.sortRequest.direction;
                } else {
                    documents.request.sortRequest.key = change;
                    documents.request.sortRequest.direction = false;
                }
                if (change !== 'srNo' && change !== 'supplier' && change !== 'tags' && change !== 'lastdateDownload' && change !== 'action') {
                    this.getDocuments(documents);
                }
                break;
            case "pageSize":
                documents.request.pageRequest[key] = change;
                documents.request.pageRequest.currentPage = 1;
                this.getDocuments(documents);
                break;
            case "currentPage":
                documents.request.pageRequest[key] = change;
                this.getDocuments(documents);
                break;
            default:
                documents.request.filterRequest[key] = change;
                this.setState({ documents });
                this.getDocuments(documents);
        }

    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.category.toLowerCase().includes(searchText);

            if (!flag && item.description) {
                flag = item.description.toLowerCase().includes(searchText)
            }

            if (!flag && item.supplier.emailAddress) {
                flag = item.supplier.emailAddress.toLowerCase().includes(searchText)
            }
            if (!flag && item.createdOn) {
                flag = getLocalDateStringFromUtcDateString(item.createdOn, 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
            if (!flag && item.lastModifiedOn) {
                flag = getLocalDateStringFromUtcDateString(item.lastModifiedOn, 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
            if (!flag && item.lastDateDownloadedBySupplier) {
                flag = getLocalDateStringFromUtcDateString(item.lastDateDownloadedBySupplier, 'DD MMM YYYY').toLowerCase().includes(searchText)
            }

        }
        return flag;
    }

    handleFineLineSelection(item) {
        let { documents } = this.state;
        if (item) {
            documents.request.filterRequest.partNumber = item.stockItemCd;
        }
        else {
            documents.request.filterRequest.partNumber = undefined;
        }
        this.setState({ documents });
        this.getDocuments(documents);
    }

    getDocumentsHeading({ filterRequest }) {
        let document = [
            {
                key: "srNo",
                label: "Sr. no",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null,
                searchNodeColSpan: 1
            },
            {
                key: "documentno",
                label: "Document no",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null,
                searchNodeColSpan: 1
            },
            {
                key: "supplier",
                label: "Supplier code",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: <SearchSmartHomeCreditor
                    handleAccountChange={(supplier) => this.handleChange(supplier ? supplier.accountID : '', "supplierCode")}
                    selectedAccountID={filterRequest.supplierCode}
                    defaultAccountID={filterRequest.supplierCode}
                    disabled={this.state.isSupplierUser}
                />
            },
            {
                key: "title",
                label: "Title",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"title"}
                        value={filterRequest.title}
                        onChange={(e) => this.handleChange(e.target.value, "title")} />
                </div>

            },
            {
                key: "PartNumber",
                label: "Part number",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: <div>
                    <SearchFineLine
                        handleSelection={(fineline) => this.handleChange(fineline ? fineline.stockItemCd : '', "partNumber")}
                        defaultValue={filterRequest.partNumber} />
                </div>
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null,
                searchNodeColSpan: 1,
            },
            {
                key: "tags",
                label: "Tags",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: <div>
                    <Input type={"select"}
                        placeholder={"select tag..."}
                        name={"tags"}
                        value={filterRequest.tags}
                        onChange={(e) => this.handleChange(e.target.value, "tags")} >
                        <option value=''>All</option>
                        {this.state.fileTagOptions.map((tag, index) => (
                            <option value={tag.name} key={index}>{tag.name}</option>
                        ))}
                    </Input>
                </div>
            },
            {
                key: "CreatedOn",
                label: "Created date",
                type: "date",
                colSpan: 1,
                minWidth: 130,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode:
                    <div>
                        <div className={"text-right mb-1"}>
                            <DatePicker
                                className="form-control form-control-sm"
                                selected={filterRequest.startDate}
                                onChange={date => this.handleChange(date, "startDate")}
                                selectsStart
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="Start date"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                        <div className={"text-right"}>
                            <DatePicker
                                className="form-control form-control-sm"
                                selected={filterRequest.endDate}
                                onChange={date => this.handleChange(date, "endDate")}
                                selectsEnd
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                placeholderText="End date"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                dropdownMode="select"
                                withPortal
                            />
                        </div>
                    </div>

            },
            {
                key: "LastModifiedOn",
                label: "Last date uploaded by SHP",
                type: "date",
                colSpan: 1,
                minWidth: "120px",
                sorterApplicable: true,
                valueClassName: "table-success text-right",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                searchNodeColSpan: 1,
            },
            {
                key: "lastdateDownload",
                label: "Last date downloaded by supplier",
                type: "date",
                colSpan: 1,
                minWidth: "120px",
                sorterApplicable: false,
                valueClassName: "table-success text-right",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: null
            },
            {
                key: "lastversionDownload",
                label: "Last version downloaded by supplier",
                type: "text",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "table-success text-right",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode:null
            }];

        return document;
    }

    getCreatedDate(createdDate, dateFormat) {
        if (createdDate) {
            let date = getLocalDateStringFromUtcDateString(createdDate, dateFormat);
            return date;
        }
    }

    getColor(document) {
        if (document.lastDateDownloadedBySupplier != null) {
            return "black";
        }
        else {
            return "green";
        }
    }

    onDownloadDocument(item) {
        item.downloading = true;
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    item.requestProgressPercentage = percent;
                }
            }
        };
        item.requestProgressPercentage = 0;
        this.setState({ downloading: true });
        SHPDocumentsService.DownloadAttachment(item.id, options).then(response => {
            this.getDocuments(this.state.documents);
            FileSaver.saveAs(response.data, item.description);
            item.downloading = false;
            item.requestProgressPercentage = 0;
            this.setState({ downloading: false });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });

            item.downloading = false;
            item.requestProgressPercentage = 0;
            this.setState({ downloading: false });
        });
    }

    handleDeleteDoc(document) {
        let { documents } = this.state;
        let index = findIndex(documents.response.records, 'id', document.id);

        documents.response.records[index].isDeleting = true;
        this.setState({ documents });
        SHPDocumentsService.deleteDocument(document.id).then(response => {
            if (response.status === 200 || response.status === '200') {
                this.getDocuments(this.state.documents);
                documents.response.records[index].isDeleting= false;
                this.setState({ documents });
                toast.success("Deleted!");
            }
        }).catch(error => {
            documents.response.records[index].isDeleting = false;
            this.setState({ documents });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let { documents, loading, searchText, isEditingAllowed } = this.state;
        let { pageRequest, sortRequest, filterRequest } = documents.request;
        let documentsHeading = this.getDocumentsHeading(documents.request);

        return (
            <div>
                {/*{isEditingAllowed ? <Row className="mb-2">
                    <Col xl={8} lg={8} md={6} sm={12} xs={12} className="text-right">Choose supplier :</Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <SHPSearchUserEmail
                            onChange={(change) => this.handleChange(change, "supplierEmail")}
                            defaultValue={filterRequest.supplierEmail} />

                    </Col>
                </Row> : null}*/}

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <h5>SHP documents</h5>
                            </Col>
                            {isEditingAllowed ? <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="text-right">
                                    <Link className="btn btn-primary btn-sm"
                                        to={"/shp/create/document"}><i className="fa fa-plus" />&nbsp;
                                    Add </Link>
                                </div>
                            </Col> : null}
                        </Row>

                    </CardHeader>
                    <CardBody>

                        <Row>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {documents.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>

                        </Row>
                        <div>
                            <Table hover responsive bordered size={"sm"}>
                                <thead>
                                    <tr>
                                        {(documentsHeading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(documentsHeading || []).map((item, index) => {
                                            if (item.searchNodeColSpan > 0) {
                                                return (
                                                    <td key={index} colSpan={item.searchNodeColSpan}
                                                        className={"align-middle"}>
                                                        {item.searchNode}
                                                    </td>
                                                );
                                            }
                                            return null;
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(documents.response.records || []).map((document, index) => {
                                        return (
                                            <tr key={index} className={"text-center"}>
                                                <td key={index}>
                                                    {/*<i class="fa fa-circle mr-2" aria-hidden="true" style={{ color: this.getColor(document) }}></i>*/}
                                                    {((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                                </td>
                                                <td className={"text-center"}>
                                                    <Link className="btn btn-sm btn-primary"
                                                        title='Click here to view document'
                                                        to={"/shp/edit/document?" + queryString.stringify({ uuid: document.id })}>{document.initialVersionSHPDocumentID}</Link>

                                                </td>
                                                <td className={"text-left"}>
                                                    {(document.supplierCodes || []).map((supplierCode, index) =>
                                                        <Badge key={index} color='primary' className={"m-1"}>{supplierCode}</Badge>
                                                    )}
                                                </td>
                                                <td className={"text-left"}>
                                                    {document.title}
                                                </td>
                                                <td className={"text-left"}>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Part Number</th>
                                                                <th>Fineline item</th>
                                                                <th>Sub brand</th>
                                                                <th>Department</th>
                                                                <th>Sub class</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(document.finelineItems || []).map((fineline, i) =>
                                                                <tr key={i}>
                                                                    <td>{fineline.stockItemCd || fineline.prodCode}</td>
                                                                    <td><small>{fineline.stockItemDescription}</small></td>
                                                                    <td>{fineline.subBrand}</td>
                                                                    <td>{fineline.department}</td>
                                                                    <td>{fineline.subClass}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                                <td className={"text-left"}>
                                                    {document.description}
                                                </td>
                                                <td className={"text-left"}>
                                                    {(document.tags || []).map((tag, index) => (
                                                        <Badge color='light' key={index}>{tag}</Badge>
                                                    ))}
                                                </td>
                                                <td className={"text-right"}>
                                                    {this.getCreatedDate(document.createdOn, "DD MMM YYYY")}
                                                    <br />
                                                    {this.getCreatedDate(document.createdOn, " hh:mm A")}
                                                </td>
                                                <td>
                                                    <div className={"text-right"}>
                                                        {this.getCreatedDate(document.lastDateUploadedBySHP, "DD MMM YYYY")}
                                                    </div>
                                                    <div className={"text-right"}>
                                                        {this.getCreatedDate(document.lastDateUploadedBySHP, "hh:mm A")}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className={"text-right"}>
                                                        {this.getCreatedDate(document.lastDateDownloadedBySupplier, "DD MMM YYYY")}
                                                    </div>
                                                    <div className={"text-right"}>
                                                        {this.getCreatedDate(document.lastDateDownloadedBySupplier, "hh:mm A")}
                                                    </div>
                                                </td>
                                                <td className={"text-right"}>
                                                    {document.lastVersionDownloadedBySupplier ?
                                                        <Button color='link' size='sm'>
                                                            <Link
                                                                title='click here to view document'
                                                                to={"/shp/edit/document?" + queryString.stringify({ uuid: document.id, documentVersion: document.lastVersionDownloadedBySupplier })}>{document.lastVersionDownloadedBySupplier}</Link>
                                                        </Button>
                                                        : null}
                                                </td>
                                                <td className='text-center'>
                                                    <Button color='danger' size='sm' disabled={document.isDeleting}
                                                        onClick={() => this.handleDeleteDoc(document)}>
                                                        {document.isDeleting ? <Spinner color='white' size='sm'/> :
                                                            <i className='fa fa-trash' />}
                                                    </Button>
                                                </td>
                                                {/*<td>
                                                    {document.fileName ?
                                                        
                                                        <div>
                                                            <Button color="link" size={"sm"} title="Click here to download"
                                                                disabled={document.downloading}
                                                                onClick={() => this.onDownloadDocument(document)}>
                                                                {document.downloading
                                                                    ? <Spinner size="sm"
                                                                        className={"mr-2"}
                                                                        color={"primary"} /> :
                                                                    <i className="fa fa-paperclip mr-1" aria-hidden="true" />
                                                                }
                                                                {document.description}
                                                            </Button>
                                                            <div>
                                                                {document.requestProgressPercentage > 0
                                                                    ? <Progress striped color="primary" value={document.requestProgressPercentage}
                                                                        className={"mt-2"} />
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : null}
                                                </td>
                                                <td><Link className="btn btn-sm btn-primary"
                                                    to={"/shp/edit/document?" + queryString.stringify({ uuid: document.id })}>Open</Link>
                                                </td>*/}
                                            </tr>
                                        );
                                    })}
                                </tbody>

                            </Table>
                            <br />
                            <Row>
                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <div className={"text-left"} style={{ maxWidth: 200 }}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Show</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"select"}
                                                name={"pageSize"}
                                                value={pageRequest.pageSize}
                                                disabled={loading}
                                                onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                <option value={10}>10 Rows</option>
                                                <option value={25}>25 Rows</option>
                                                <option value={50}>50 Rows</option>
                                                <option value={100}>100 Rows</option>
                                                <option value={500}>500 Rows</option>
                                            </Input>
                                        </InputGroup>


                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"float-right"}>
                                        <Pagination
                                            activePage={pageRequest.currentPage}
                                            itemsCountPerPage={pageRequest.pageSize}
                                            totalItemsCount={documents.response.totalRecords}
                                            pageRangeDisplayed={3}
                                            onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                            itemClass='page-item'
                                            linkClass='page-link'
                                            activeClass='active'
                                            innerClass='pagination'
                                            activeLinkClass='active'
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                    <ToastContainer />
                </Card>
            </div>
        );
    }
}