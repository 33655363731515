import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';
import SmartHomeCreditorSevice from '../../services/SmartHomeCreditorSevice';
import {handleErrorMessage} from "../../services/CommonService";

export default class SearchSmartHomeCreditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            supplier: {},
            formError: "",
            accountID: props.defaultAccountID,
            company: "",
            account: undefined,
            loading: {
                suppliers: false
            },
            suppliers: [],
            inputValue: ""
        }

        this.prepareFetchOptions = this.prepareFetchOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchOptions(this.state.accountID);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedAccountID) {
            if (this.state.accountID !== nextProps.selectedAccountID) {
                //load externally selectedItem
                this.fetchOptions(nextProps.selectedAccountID);
            }
        }
    }


    fetchOptions(inputValue, callback) {
        let suppliers = [];
        let { loading } = this.state;

        loading.suppliers = true;
        this.setState({ loading });
        SmartHomeCreditorSevice.getSmartHomeCreditorSummary(inputValue).then(response => {

            let data = response.data;
            for (let i in data) {
                let account = {
                    label: data[i].accountID + " | " + (data[i].company ? data[i].company : ""),
                    value: data[i].accountID,
                    id: data[i].id,
                    accountID: data[i].accountID,
                    company: data[i].company,
                    isLocalCurrency: data[i].isLocalCurrency,
                    currency: data[i].currency,

                };
                suppliers.push(cloneDeep(account));
                if (this.props.defaultAccountID) {
                    if (data[i].accountID === this.props.defaultAccountID) {
                        this.handleChange(account);// for default purpose
                    }
                }
                
            }
            loading.suppliers = false;
            this.setState({suppliers, loading});
            if (callback) {
                return callback(suppliers)
            } else {
                return suppliers;
            }
        }).catch(error => {
                loading.suppliers = false;
                this.setState({loading});
                alert(handleErrorMessage(error));
            }
        );
    }

    prepareFetchOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 1) {
                return this.fetchOptions(inputValue, callback)
            }
            if (inputValue && inputValue.length <= 1) {
                return this.fetchOptions("", callback)
            }
            callback(this.state.suppliers);

        }
    };

    handleChange = (selectedAccount) => {
        this.setState({ supplier: selectedAccount });
        if (selectedAccount) {
            if (this.props.handleAccountChange) {
                this.props.handleAccountChange(selectedAccount);
            }
            this.setState({
                supplier: selectedAccount,
                accountID: selectedAccount.accountID,
                company: selectedAccount.company,
                account: selectedAccount
            });
        } else {
            this.setState({ supplier: selectedAccount, accountID: "" });
            this.fetchOptions();
            if (this.props.handleAccountChange) {
                this.props.handleAccountChange(selectedAccount)
            }
        }
    }

    render() {
        let {supplier, loading, suppliers} = this.state;
        return (
            <AsyncSelect
                isDisabled={this.props.disabled}
                cacheOptions
                isLoading={loading.suppliers}
                isClearable={true}
                defaultOptions={suppliers}
                loadOptions={this.prepareFetchOptions}
                onChange={this.handleChange}
                value={supplier}
                placeholder={"Search..."}
            />
        )
    }
}