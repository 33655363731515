import axios from './axios';
import { config } from '../helpers/config';
import { authHeader } from '../helpers/auth-header';

class SmartHomeCreditorService {

    static Instance() {
        return new SmartHomeCreditorService();
    }

    getSmartHomeCreditorSummary(searchText) {
        let requestBody = {
            accountID: searchText
        };
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            data: JSON.stringify(requestBody),
        };
        return axios(config.apiUrl + 'api/creditor/smarthomeDb/summary/list', requestOptions);
    }
}

export default SmartHomeCreditorService.Instance();