import React, { Component } from 'react';
import { Link as Link } from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Spinner,
    Progress,
    Badge,
    Table,
    FormText
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLocalDateStringFromUtcDateString, handleErrorMessage } from '../../services/CommonService';
import SHPDocumentsService from '../../services/SHP/SHPDocumentsService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FileSaver from 'file-saver';
import * as Editor from '../../store/ReactQuillEditorConfig';
import SHPUserService from '../../services/SHP/SHPUserService';
import SHPDocumentHistoryModal from './SHPDocumentHistoryModal';
import queryString from 'query-string';
import moment from 'moment';
import SHPVersionControlModal from './SHPVersionControlModal';
import UploadFiles from '../attachment/UploadFiles';
import SearchFineLine from "../search/SearchFineLine";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import SearchSmartHomeCreditor from '../search/SearchSmartHomeCreditor';

export default class ManageDocument extends Component {
    static displayName = ManageDocument.name;

    constructor(props) {
        super(props);

        this.state = {
            isEditingAllowed: false,
            document: {
                id: 0,
                category: '',
                notes: '',
                description: '',
                fileName: '',
                group: '',
                title: '',
                selectedFile: null,
                fileSizeText: '',
                supplier: { emailAddress: '' },
                attachmentIDs: '',
                dimStockItem: undefined,
                shpTags: [],
                supplierCodes: [],
                shpDocumentFinelineItems: []
            },
            saving: false,
            downloading: false,
            fetching: false,
            requestProgressPercentage: 0,
            isOpenHistoryModal: false,
            isOpenVersionModal: false,
            currentUser: {},
            documentHistory: [],
            loading: {
                documentHistoryList: false
            },
            formErrors: {},
            fileTagOptions: [],
            selectedFileTags: []

        };

        this.handleChange = this.handleChange.bind(this);
        this.saveDocument = this.saveDocument.bind(this);
        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.onDownloadDocument = this.onDownloadDocument.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.toggleHistoryModal = this.toggleHistoryModal.bind(this);
        this.getDocument = this.getDocument.bind(this);
        this.toggleVersionModal = this.toggleVersionModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleFineLineSelection = this.handleFineLineSelection.bind(this);
        this.getTags = this.getTags.bind(this);
    }

    componentDidMount() {
        let { document } = this.state;
        let currentUser = SHPUserService.getLoggedInUser();
        let isEditingAllowed = SHPUserService.hasPrivilege(currentUser, "shp-artwork-attachment-write");
        this.setState({ currentUser, isEditingAllowed });
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            document.id = searchParams.uuid;
            this.setState({ requestProgressPercentage: 0 });
            this.getDocument(document);
        }
        this.getTags();
    }

    componentWillReceiveProps(nextProps) {
        let { document } = this.state;
        let isEditingAllowed = SHPUserService.hasPrivilege(this.state.currentUser, "shp-artwork-attachment-write");
        this.setState({ isEditingAllowed });
        if (nextProps.location.search) {
            let searchParams = queryString.parse(nextProps.location.search);
            if (document.id !== searchParams.documentId) {
                document.id = searchParams.uuid;
                this.setState({ requestProgressPercentage: 0 });
                this.getDocument(document);
            }
        }
    }

    getTags() {
        SHPDocumentsService.getTags().then(response => {
            let data = response.data;
            let fileTagOptions = [];
            data.forEach(tag => {
                fileTagOptions.push({ label: tag.name, value: tag.id })
            })
            this.setState({ fileTagOptions });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    getDocument(document) {
        if (document.id !== 0) {
            this.setState({ fetching: true });
            SHPDocumentsService.getDocument(document.id).then(response => {
                let selectedFileTagsArray = new Array();
                if (response.data.shpTags && response.data.shpTags.length !== 0) {

                    (response.data.shpTags || []).map(type => {
                        let option = {
                            label: type.shpTag.name,
                            value: type.shpTagID
                        }
                        selectedFileTagsArray.push(option);

                    });
                }
                this.setState({ document: response.data, selectedFileTags: selectedFileTagsArray, requestProgressPercentage: 0, fetching: false });
            }).catch(error => {
                this.setState({ fetching: false });
                toast.error(handleErrorMessage(error));
            });
        }
    }

    handleChange(change, key) {
        let { document } = this.state;
        if (key === "selectedSupplierCode") {
            if (change === null) {
                document[key] = null;
            }
            else {
                document[key] = change.accountID;
                if (!document.supplierCodes) {
                    document.supplierCodes = [];
                }
                if (!document.supplierCodes.includes(change.accountID)) {
                    document.supplierCodes.push(change.accountID);
                }
                document[key] = null
            }
        }
        else if (key === "shpTags") {
            document[key] = change;
            this.setState({ selectedFileTags: change });
        }
        else {
            document[key] = change;
        }
        this.setState({ document });
    }

    handleFineLineSelection(finelineItemIndex, item) {
        let { document } = this.state;

        if (item) {
            document.shpDocumentFinelineItems[finelineItemIndex].swishDWDimStockItemID = item.dimStockItemId;
            document.shpDocumentFinelineItems[finelineItemIndex].dimStockItem = item;
            document.shpDocumentFinelineItems[finelineItemIndex].supplierCode = item.supplierCode;
            document.shpDocumentFinelineItems[finelineItemIndex].prodCode = item.prodCode;
            document.shpDocumentFinelineItems[finelineItemIndex].subDepartment = item.dimSubDepartment.subDepartment;
            document.shpDocumentFinelineItems[finelineItemIndex].subClass = item.dimSubClass.subClass;

        }
        else {
            document.shpDocumentFinelineItems[finelineItemIndex].swishDWDimStockItemID = null;
            document.shpDocumentFinelineItems[finelineItemIndex].dimStockItem = null;
            document.shpDocumentFinelineItems[finelineItemIndex].supplierCode = '';
            document.shpDocumentFinelineItems[finelineItemIndex].prodCode = '';
            document.shpDocumentFinelineItems[finelineItemIndex].subDepartment = '';
            document.shpDocumentFinelineItems[finelineItemIndex].subClass = '';
        }

        this.setState({ document });
    }

    handleAddFinelineItems() {
        let { document } = this.state;
        if (!document.shpDocumentFinelineItems) {
            document.shpDocumentFinelineItems = [];
        }
        let finelineItem = {
            swishDWDimStockItemID: 0,
            dimStockItem: null,
            supplierCode: '',
            prodCode: '',
            subDepartment: '',
            subClass: ''
        };

        document.shpDocumentFinelineItems.push(finelineItem);
        this.setState({
            document,
        });
    }

    handleDeleteFineline(finelneItemIndex) {
        let { document } = this.state;
        document.shpDocumentFinelineItems.splice(finelneItemIndex, 1);
        this.setState({
            document,
        });
    }

    handleDeleteSupplier(supplierIndex) {
        let { document } = this.state;
        document.supplierCodes.splice(supplierIndex, 1);
        this.setState({
            document,
        });
    }

    fileChangeHandler(event) {
        let name = event.target.files[0].name;
        this.setState({ selectedFile: event.target.files[0] });
        for (let i = 0; i < event.target.files.length; i++) {
            if (event.target.files[i].size > 27262976) {
                this.setState({ fileSizeText: 'File size must be less than 26 MB' })
            } else {
                this.setState({ fileSizeText: '' })
            }
        }
    }

    onDownloadDocument(item) {
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    this.setState({ requestProgressPercentage: percent });
                }
            }
        };
        this.setState({ downloading: true, requestProgressPercentage: 0 });
        SHPDocumentsService.DownloadAttachment(item.id, options).then(response => {
            FileSaver.saveAs(response.data, item.description);
            this.setState({ downloading: false, requestProgressPercentage: 0 });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(error);
            this.setState({ downloading: false, requestProgressPercentage: 0 });
        });
    }

    validateRequest() {
        let { selectedFileTags } = this.state;
        let document = { ...this.state.document };
        let formErrors = {};
        let valid = true;

        if (!document.title) {
            formErrors.title = "Document title is required";
            valid = false;
        }
        if (document.title && document.title.length > 250) {
            formErrors.title = "Max allowed 250 characters";
            valid = false;
        }

        if (document.description && document.description.length > 250) {
            formErrors.description = "Max allowed 250 characters";
            valid = false;
        }

        if (document.notes === "" || document.notes === null) {
            formErrors.notes = "Notes is required";
            valid = false;
        }

        /*if ((document.supplier===null) || (document.supplier !== null && document.supplier.emailAddress === "")) {
            formErrors.supplier = "Select supplier to share the document";
            valid = false;
        }*/

        if (!document.shpDocumentFinelineItems || (document.shpDocumentFinelineItems && document.shpDocumentFinelineItems.length === 0)) {
            formErrors.shpDocumentFinelineItems = "Please add fineline items";
            valid = false;
        }

        if (!selectedFileTags || selectedFileTags.length === 0) {
            formErrors.tag = "Please select tag";
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    saveDocument() {
        let result = this.validateRequest();

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }

        else {

            let { selectedFileTags } = this.state;
            let document = { ...this.state.document };
            let shpTagIds = [];
            if (selectedFileTags && selectedFileTags.length !== 0) {
                selectedFileTags.forEach(tag => {
                    shpTagIds.push(tag.value);
                });
            }

            let req = { ...document };
            if (shpTagIds.length > 0) {
                req.SHPTagIDs = shpTagIds.toString();
            }

            if (!req.supplierCodes) {
                req.supplierCodes = [];
            }

            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    if (percent < 100) {
                        this.setState({ requestProgressPercentage: percent });
                    }
                }
            };

            this.setState({ requestProgressPercentage: 0 });
            if (document.id !== 0) {
                this.setState({ req, isOpenVersionModal: true });
            }
            else if (document.id === 0) {

                this.setState({ saving: true });
                SHPDocumentsService.saveDocument(req, options).then(response => {
                    if (response.status === 200 || response.status === '200') {
                        this.props.history.push('/shp/documents');
                        toast.success("Document saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                        this.setState({ saving: false, requestProgressPercentage: 0, formErrors: {} });
                    }
                }).catch(error => {
                    toast.error(handleErrorMessage(error), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({ saving: false, requestProgressPercentage: 0 });
                });
            }
        }
    }

    handleCancel() {
        this.props.history.push('/shp/documents');
    }

    toggleHistoryModal(change) {
        this.setState({ isOpenHistoryModal: change });
    }

    toggleVersionModal(change) {
        this.setState({ isOpenVersionModal: change });
    }

    getTime(date) {
        return moment.utc(date).fromNow();
    }

    handleCloseModal(data, isOpenVersionModal) {
        let element = document.getElementById("fileInputId");
        if (element) {
            element.value = "";
        }
        this.props.history.push('/shp/documents');
        this.setState({ isOpenVersionModal: isOpenVersionModal, saving: false, document: data });
    }

    handleFinelineChange(index, value, key) {
        let { document } = this.state;
        document.shpDocumentFinelineItems[index][key] = value;
        this.setState({ document });
    }

    render() {
        const document = this.state.document;
        let {  isEditingAllowed, saving,  fetching, requestProgressPercentage, formErrors } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/shp/documents">Documents</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Manage</BreadcrumbItem>
                </Breadcrumb>
                <div>

                    {fetching
                        ? <Spinner size={"sm"} color={"primary"} />
                        : <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h5 className={"text-left"}>{this.state.document.id == 0 ? 'Create document' : isEditingAllowed ? 'Update document' : 'View document'}</h5>
                                    </Col>
                                    {this.state.document.id != 0 ? <div className="text-right">
                                        <Link className='btn btn-primary btn-sm'
                                            to={"/shp/document/history?" + queryString.stringify({ uuid: this.state.document.id })}>View
                                                        history</Link>
                                    </div> : null}
                                </Row>

                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}
                                        className={"border-right mt-2"}>
                                        <div>
                                            <FormGroup>
                                                <Label for="title">Title</Label>
                                                <Input type="text" name="title" id="title" plaintext={!isEditingAllowed}
                                                    placeholder="Type a title here..."
                                                    value={document.title || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, "title")}
                                                    autoComplete="off" />
                                                <FormText color="danger"> {formErrors.title}</FormText>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>Supplier</Label>
                                                <SearchSmartHomeCreditor
                                                    handleAccountChange={(supplierCode) => this.handleChange(supplierCode, "selectedSupplierCode")}
                                                    selectedAccountID={document.selectedSupplierCode}
                                                    defaultAccountID={document.selectedSupplierCode} />

                                                {(document.supplierCodes || []).map((supplierCode, supplierIndex) => (
                                                    <Badge key={supplierIndex}
                                                        color="primary"
                                                        className={"m-1"}
                                                    >{supplierCode} <i className='fa fa-times ml-2 hand-cursor'
                                                        onClick={() => this.handleDeleteSupplier(supplierIndex)} /></Badge>
                                                ))}
                                                <FormText color="danger"> {formErrors.supplierCode}</FormText>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="description">Description</Label>
                                                <Input type="textarea" name="description" id="description" plaintext={!isEditingAllowed}
                                                    placeholder="Type a description here..."
                                                    value={document.description || ''}
                                                    onChange={(e) => this.handleChange(e.target.value, "description")}
                                                    autoComplete="off" />
                                                <FormText color="danger"> {formErrors.description}</FormText>
                                            </FormGroup>

                                            { /*<FormGroup>
                                                <Label for="group">Supplier</Label>
                                                <SHPSearchUserEmail
                                                    onChange={(change) => this.handleChange(change, "supplierEmail")}
                                                    defaultValue={document.supplier ? document.supplier.emailAddress : ""} disabled={!isEditingAllowed} />
                                                <FormText color="danger"> {formErrors.supplier}</FormText>
                                            </FormGroup>*/}

                                            <FormGroup>
                                                <Label>Tag</Label>
                                                <CreatableSelect
                                                    isMulti
                                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                    placeholder={"Add file tag"}
                                                    onChange={(value) => this.handleChange(value, "shpTags")}
                                                    value={this.state.selectedFileTags}
                                                    options={this.state.fileTagOptions}
                                                />
                                                <FormText color="danger"> {formErrors.tag}</FormText>
                                            </FormGroup>
                                            <UploadFiles
                                                isReadOnly={!isEditingAllowed}
                                                handleChange={(change) => this.handleChange(change, "attachmentIDs")}
                                                attachmentIDs={document.attachmentIDs}
                                                documentID={document.id}
                                                isDownloadAllFilesBtnVisible={true}
                                            />


                                            {/*{(document.id && document.fileName) ?
                                                <FormGroup>
                                                    <Button color="primary"
                                                            size={"sm"}
                                                            block={true}
                                                            outline={true}
                                                            title="Click here to download"
                                                            className="mt-4"
                                                            disabled={downloading}
                                                            onClick={() => this.onDownloadDocument(document)}>
                                                        {downloading
                                                            ? <Spinner size="sm"
                                                                       className={"mr-2"}
                                                                       color={"primary"}/>
                                                            : <i className="fa fa-download mr-2"/>
                                                        }
                                                        Download
                                                    </Button>
                                                </FormGroup>
                                                : null
                                            }*/}
                                        </div>
                                    </Col>
                                    <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                        <Table hover bordered size={"sm"} responsive style={{ minHeight: '300px' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ minWidth: '180px' }}>Fineline</th>
                                                    <th style={{ minWidth: '150px' }}>Prodcode</th>
                                                    <th style={{ minWidth: '110px' }}>Sub class</th>
                                                    <th style={{ minWidth: '110px' }}>Sub department</th>
                                                    <th style={{ minWidth: '80px' }} className='text-center'>Action <br />
                                                        <Button size='sm' onClick={() => this.handleAddFinelineItems()}>Add <i className='fa fa-plus mr-2' /></Button></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(document.shpDocumentFinelineItems || []).map((fineline, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <FormGroup>
                                                                <SearchFineLine handleSelection={(value) => this.handleFineLineSelection(i, value)} defaultValue={fineline.swishDWDimStockItemID} />
                                                            </FormGroup>
                                                        </td>
                                                        <td>
                                                            <Input type="text"
                                                                placeholder='Enter prodcode here'
                                                                onChange={(e) => this.handleFinelineChange(i, e.target.value, "prodCode")}
                                                                value={fineline.prodCode || ''} />
                                                            <FormText color="danger"> {formErrors.prodCode}</FormText>
                                                        </td>
                                                        <td>
                                                            <Input type="text"
                                                                placeholder='Enter sub class here'
                                                                onChange={(e) => this.handleFinelineChange(i, e.target.value, "subClass")}
                                                                value={fineline.subClass || ''} />
                                                        </td>
                                                        <td>
                                                            <Input type="text"
                                                                placeholder='Enter sub department here'
                                                                onChange={(e) => this.handleFinelineChange(i, e.target.value, "subDepartment")}
                                                                value={fineline.subDepartment || ''} />
                                                        </td>
                                                        <td className='text-center'>
                                                            <Button color='danger' size='sm' onClick={() => this.handleDeleteFineline(i)}>
                                                                <i className='fa fa-trash' />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <FormText color="danger"> {formErrors.shpDocumentFinelineItems}</FormText>

                                        <FormGroup>
                                            <Label for="description">Notes</Label>
                                            <div className="quil-editor-root ">
                                                {isEditingAllowed ? < ReactQuill value={document.notes}
                                                    readOnly={!isEditingAllowed}
                                                    placeholder="Type description here..."
                                                    modules={isEditingAllowed ? Editor.modules : {}}
                                                    formats={isEditingAllowed ? Editor.formats : []}
                                                    onChange={(change) => this.handleChange(change, "notes")}
                                                    bounds={'.quil-editor-root'} />
                                                    :
                                                    <ReactQuill
                                                        value={document.notes}
                                                        readOnly={true}
                                                        theme={"bubble"}
                                                    />}
                                            </div>
                                            <FormText color="danger"> {formErrors.notes}</FormText>
                                        </FormGroup>

                                    </Col>
                                </Row>
                                <br />
                                {document.createdByUser
                                    ? <div className="text-muted">Posted
                                        &nbsp;by<i className="fa fa-user-o ml-2 mr-1"
                                            aria-hidden="true" />{document.createdByUser.name || ''}
                                        &nbsp;on<i className="fa fa-calendar ml-2 mr-1"
                                            aria-hidden="true" />{getLocalDateStringFromUtcDateString(document.createdOn, 'DD MMM YYYY hh:mm A')}
                                       &nbsp; ({this.getTime(document.createdOn)})
                                    </div>
                                    : null
                                }
                                {
                                    document.lastModifiedByUser
                                        ? <div className="mt-2 text-muted">Last updated
                                            &nbsp;by<i className="fa fa-user-o ml-2 mr-1"
                                                aria-hidden="true" />{document.lastModifiedByUser.name || ''}
                                            &nbsp;on<i className="fa fa-calendar ml-2 mr-1"
                                                aria-hidden="true" />{getLocalDateStringFromUtcDateString(document.lastModifiedOn, 'DD MMM YYYY hh:mm A')}
                                            &nbsp; ({this.getTime(document.lastModifiedOn)})
                                        </div>
                                        : null
                                }
                                <div>
                                    {requestProgressPercentage > 0
                                        ? <Progress striped color="primary" value={requestProgressPercentage}
                                            className={"mt-2"} />
                                        : null
                                    }
                                </div>
                            </CardBody>

                            <CardFooter>

                                <div className="text-right">
                                    {
                                        isEditingAllowed
                                            ? <Button color="primary" onClick={this.saveDocument} size={"sm"}
                                                disabled={saving}>
                                                {saving
                                                    ? <Spinner size="sm"
                                                        className={"mr-2"}
                                                        style={{ color: "white" }} />
                                                    : <i className="fa fa-floppy-o mr-2" />
                                                }
                                                {saving
                                                    ? "Saving"
                                                    : "Save"
                                                }

                                            </Button>
                                            : null
                                    }

                                    <Button color="secondary" className="ml-2" size={"sm"}
                                        onClick={this.handleCancel}>
                                        <i className="fa fa-times mr-2" />Close</Button>
                                </div>
                            </CardFooter>

                        </Card>
                    }


                </div>
                <SHPDocumentHistoryModal isOpen={this.state.isOpenHistoryModal}
                    toggle={this.toggleHistoryModal}
                    documentHistory={this.state.documentHistory}
                    loading={this.state.loading} />

                <SHPVersionControlModal isOpen={this.state.isOpenVersionModal}
                    toggle={this.toggleVersionModal} data={this.state.data} handlerCloseModal={this.handleCloseModal}
                />
                <ToastContainer />
            </div>
        );
    }
}
