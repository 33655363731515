import axios from '../axios';

class SHPUserService {
    static Instance() {
        return new SHPUserService()
    }
    
    
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
    }

    getUserByUserId(userId) {
        return axios.get('api/shp/user/' + userId);
    }

    listUsers(req) {
        return axios.post('api/shp/user/list',req);
    }

    updatePasswordByAdmin(password, repassword, email) {
        let requestBody = {
            password: password,
            repassword: repassword,
            token: email
        };
        return axios.put('api/shp/user/update/password', requestBody);
    }

    async getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getLoggedInUser() {
        return JSON.parse(localStorage.getItem('user'));
    }


    hasPrivilege(user, privilege) {
        if (user) {
            return user.menu.privileges.includes(privilege);
        }
        return false;
    }

    saveUser(user) {
        if (user.id) {
            return axios.put('api/shp/user/update', user)
        } else {
            return axios.post('api/shp/user/save', user)
        }
    }

    listRoles() {
        return axios.get('api/shp/role/list');
    }

    listUserRoles(id) {

        let url = 'api/shp/user/' + id + '/role'
        return axios.get(url)
    }

    addRole(id, newrole) {
        newrole.userId = id;
        return axios.post('api/shp/user/addrole', newrole);
    }

    setPrimaryRole(userRoleId) {
        let url = 'api/shp/user/role/primary?userRoleId=' + userRoleId;
        return axios.post(url);
    }

    removeRole(id) {
        return axios.delete('api/shp/user/removerole/' + id);
    }

    
    deleteUser(id) {
        return axios.put('api/shp/user/deleteuser/' + id);
    }

    getUserStats() {
        return axios.get('api/shp/user/stats');
    }

    searchUser(query) {
        return axios.post('api/shp/user/search', { query: query });
    }

    getUserCategories() {
        return axios.get('api/shp/user/categories');
    }

}
export default SHPUserService.Instance()