import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardFooter, Spinner, Button, Badge,
    Row, Col, Input, Label, FormGroup, FormText, Progress, Collapse, Table
} from 'reactstrap';
import SHPDocumentsService from '../../services/SHP/SHPDocumentsService';
import SearchFineLine from '../search/SearchFineLine';
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import UploadFiles from '../attachment/UploadFiles';
import { getLocalDateStringFromUtcDateString, findIndex, handleErrorMessage } from '../../services/CommonService';
import moment from 'moment';
import SHPUserService from '../../services/SHP/SHPUserService';
import classnames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Editor from '../../store/ReactQuillEditorConfig';
import SHPVersionControlModal from './SHPVersionControlModal';
import queryString from 'query-string';
import { Link as Link } from "react-router-dom";
import SearchSmartHomeCreditor from '../search/SearchSmartHomeCreditor';

export default class SHPDocumentVersions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditingAllowed: false,
            documentVersions: [],
            documentID: this.props.documentID,
            formErrors: [],
            fileTagOptions: [],
            saving: false,
            downloading: false,
            fetching: false,
            requestProgressPercentage: 0,
            isOpenHistoryModal: false,
            isOpenVersionModal: false,
            currentUser: {},
            documentVersion: this.props.documentVersion,
            selectedVersion: undefined,
        }

        this.toggleVersionModal = this.toggleVersionModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.getTags = this.getTags.bind(this);

        this.getDocumentWithVersionDetails = this.getDocumentWithVersionDetails.bind(this);
    }

    componentDidMount() {
        let { documentID, documentVersion } = this.state;
        let currentUser = SHPUserService.getLoggedInUser();
        let isEditingAllowed = SHPUserService.hasPrivilege(currentUser, "shp-artwork-attachment-write");
        this.setState({ currentUser, isEditingAllowed });
        this.getTags();

        this.getDocumentWithVersionDetails(documentID, documentVersion);
    }

    componentWillReceiveProps(nextProps) {
        let { documentID, documentVersion, documentVersions, selectedVersion } = this.state;
        if (nextProps.documentVersion !== this.state.documentVersion) {
            documentVersion = nextProps.documentVersion;
            this.setState({ documentVersion: nextProps.documentVersion });
        }

        if (nextProps.selectedVersion !== this.state.selectedVersion) {
            selectedVersion = nextProps.selectedVersion;
            documentVersions.map((document) => {
                if (document.version === selectedVersion) {
                    document.isOpenCollapse = true;
                }
                else {
                    document.isOpenCollapse = false;
                }
            })
            this.setState({ selectedVersion, documentVersions });

        }

        if (nextProps.documentID !== this.state.documentID) {
            this.setState({
                documentID: nextProps.documentID
            }, () => { this.getDocumentWithVersionDetails(this.state.documentID, documentVersion) })
        }




    }

    getDocumentWithVersionDetails(documentID, documentVersion) {
        if (documentID !== 0) {
            let { documentVersions } = this.state;
            this.setState({ fetching: true });



            SHPDocumentsService.getDocumentWithVersionDetails(documentID).then(response => {
                documentVersions = response.data;

                let documentVersionIndex = undefined;
                if (documentVersion) {
                    documentVersionIndex = findIndex(documentVersions, 'version', documentVersion);
                }

                this.props.handleVersionCount(documentVersions.length);
                documentVersions.map((document, index) => {
                    if (!documentVersionIndex) {
                        if (index === 0) {
                            document.isOpenCollapse = true;
                        }
                        else {
                            document.isOpenCollapse = false;
                        }
                    }
                    else {
                        if (index === documentVersionIndex) {
                            document.isOpenCollapse = true;
                        }
                    }

                    document.supplierCodes = new Array();
                    (document.shpDocumentSuppliers || []).map(supplier => {
                        document.supplierCodes.push(supplier.supplierCode);
                    });

                    document.selectedFileTagsArray = new Array();

                    if (document.shpTags && document.shpTags.length !== 0) {

                        (document.shpTags || []).map(type => {
                            let option = {
                                label: type.shpTag.name,
                                value: type.shpTagID
                            }
                            document.selectedFileTagsArray.push(option);

                        });
                    }
                })

                this.setState({ documentVersions, fetching: false });
            }).catch(error => {
                this.setState({ fetching: false });
                toast.error(handleErrorMessage(error));
            });
        }
    }

    getTags() {
        SHPDocumentsService.getTags().then(response => {
            let data = response.data;
            let fileTagOptions = [];
            data.forEach(tag => {
                fileTagOptions.push({ label: tag.name, value: tag.id })
            })
            this.setState({ fileTagOptions });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    getTime(date) {
        return moment.utc(date).fromNow();
    }

    toggleCollapse(index, value) {
        let { documentVersions } = this.state;
        documentVersions[index].isOpenCollapse = value;

        this.setState({ documentVersions });
    }

    handleChange(index, change, key) {
        let { documentVersions } = this.state;
        if (key === "selectedSupplierCode") {
            if (change === null) {
                documentVersions[index][key] = null;
            }
            else {
                documentVersions[index][key] = change.accountID;
                if (!documentVersions[index].supplierCodes) {
                    documentVersions[index].supplierCodes = [];
                }
                if (!documentVersions[index].supplierCodes.includes(change.accountID)) {
                    documentVersions[index].supplierCodes.push(change.accountID);
                }
                documentVersions[index][key] = null
            }
        }
        else if (key === "shpTags") {
            documentVersions[index][key] = change;
            documentVersions[index].selectedFileTagsArray = change;
            this.setState({ documentVersions });
        }
        else {
            documentVersions[index][key] = change;
        }
        this.setState({ documentVersions });
    }


    validateRequest(document, index) {

        let { formErrors } = this.state;
        let valid = true;

        formErrors[index] = {};
        if (document.title === "" || document.title === null) {
            formErrors[index].title = "Document title is required";
            valid = false;
        }
        if (document.title && document.title.length > 250) {
            formErrors[index].title = "Max allowed 250 characters";
            valid = false;
        }
        if (document.description && document.description.length > 250) {
            formErrors[index].description = "Max allowed 250 characters";
            valid = false;
        }

        if (document.notes === "" || document.notes === null) {
            formErrors[index].notes = "Notes is required";
            valid = false;
        }

        if (!document.shpDocumentFinelineItems || (document.shpDocumentFinelineItems && document.shpDocumentFinelineItems.length === 0)) {
            formErrors[index].shpDocumentFinelineItems = "Please add fineline items";
            valid = false;
        }

        if (!document.selectedFileTagsArray || (document.selectedFileTagsArray && document.selectedFileTagsArray.length === 0)) {
            formErrors[index].tag = "Please select tag";
            valid = false;
        }

        return { valid: valid, formErrors: formErrors };
    }

    saveDocument(index) {
        let { documentVersions, formErrors } = this.state;
        let document = documentVersions[index];

        let result = this.validateRequest(document, index);

        if (!result.valid) {
            this.setState({ formErrors: result.formErrors });
            return;
        }

        else {

            let shpTagIds = [];
            if (document.selectedFileTagsArray && document.selectedFileTagsArray.length !== 0) {
                document.selectedFileTagsArray.forEach(tag => {
                    shpTagIds.push(tag.value);
                });
            }

            let req = { ...document };
            if (shpTagIds.length > 0) {
                req.SHPTagIDs = shpTagIds.toString();
            }

            if (!req.supplierCodes) {
                req.supplierCodes = [];
            }

            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    if (percent < 100) {
                        document.requestProgressPercentage = percent;
                        this.setState({ documentVersions });
                    }
                }
            };

            document.requestProgressPercentage = 0;
            this.setState({ documentVersions });

            if (index === 0) {
                this.setState({ req, isOpenVersionModal: true });
            }

            else {
                req.isNewVersion = false;
                documentVersions[index].saving = true;
                this.setState({ documentVersions });
                SHPDocumentsService.updateDocument(req, options).then(response => {
                    if (response.status === 200 || response.status === '200') {
                        toast.success("Document saved successfully!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        documentVersions[index].saving = false;
                        documentVersions[index].requestProgressPercentage = 0;

                        formErrors[index] = {};
                        this.setState({ documentVersions, formErrors });

                        this.props.triggerHistoryRefresh();
                    }
                }).catch(error => {
                    toast.error(JSON.stringify(error.message), {
                        position: toast.POSITION.BOTTOM_CENTER
                    });

                    documentVersions[index].saving = false;
                    documentVersions[index].requestProgressPercentage = 0;
                    this.setState({ documentVersions });
                });
            }
        }
    }

    handleFineLineSelection(documentVesionIndex, finelineItemIndex, item) {
        let { documentVersions } = this.state;

        if (item) {
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].swishDWDimStockItemID = item.dimStockItemId;
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].dimStockItem = item;
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].supplierCode = item.supplierCode;
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].prodCode = item.prodCode;
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].subDepartment = item.dimSubDepartment.subDepartment;
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].subClass = item.dimSubClass.subClass;

        }
        else {
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].swishDWDimStockItemID = null;
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].dimStockItem = null;
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].supplierCode = '';
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].prodCode = '';
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].subDepartment = '';
            documentVersions[documentVesionIndex].shpDocumentFinelineItems[finelineItemIndex].subClass = '';
        }

        this.setState({ documentVersions });
    }

    handleAddFinelineItems(index) {
        let { documentVersions } = this.state;
        if (!documentVersions[index].shpDocumentFinelineItems) {
            documentVersions[index].shpDocumentFinelineItems = [];
        }
        let finelineItem = {
            swishDWDimStockItemID: 0,
            dimStockItem: null,
            supplierCode: '',
            prodCode: '',
            subDepartment: '',
            subClass: ''
        };

        documentVersions[index].shpDocumentFinelineItems.push(finelineItem);
        this.setState({
            documentVersions,
        });
    }

    handleDeleteFineline(documentVersionIndex, finelineItemIndex) {
        let { documentVersions } = this.state;
        documentVersions[documentVersionIndex].shpDocumentFinelineItems.splice(finelineItemIndex, 1);
        this.setState({
            documentVersions,
        });
    }

    handleDeleteSupplier(documentVersionIndex, supplierIndex) {
        let { documentVersions } = this.state;
        documentVersions[documentVersionIndex].supplierCodes.splice(supplierIndex, 1);
        this.setState({
            documentVersions,
        });
    }

    toggleVersionModal(change) {
        this.setState({ isOpenVersionModal: change });
    }


    handleCloseModal(data, isOpenVersionModal) {
        let element = document.getElementById("fileInputId");
        if (element) {
            element.value = "";
        }
        this.props.history.push('/shp/documents');
        this.setState({ isOpenVersionModal: isOpenVersionModal, saving: false });
    }

    onSelectHistory(document) {
        this.setState({ documentVersion: document.version });

        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("documentVersion", document.version);

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    handleFinelineChange(documentVersionIndex, finelineIndex, value, key) {
        let { documentVersions } = this.state;
        documentVersions[documentVersionIndex].shpDocumentFinelineItems[finelineIndex][key] = value;
        this.setState({ documentVersions });
    }

    render() {
        let { fileTagOptions, documentVersions, isEditingAllowed, formErrors, fetching } = this.state;
        return (
            <div>
                {fetching ? <Spinner color='primary' size='sm' /> :
                    <div>
                        {(documentVersions || []).map((document, index) => (
                            <Card key={index} className='mt-2'>
                                <CardHeader>
                                    <Row>
                                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                                            <Button color={"link"}
                                                size={"sm"}
                                                onClick={() => this.toggleCollapse(index, !document.isOpenCollapse)}>
                                                <i className={classnames("fa", "fa-lg", "fw", "mr-3", {
                                                    "fa-angle-up": document.isOpenCollapse,
                                                    "fa-angle-down": !document.isOpenCollapse,
                                                }
                                                )} aria-hidden="true" />Version &nbsp;{document.version}
                                            </Button>
                                        </Col>
                                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                                            <div className='text-right'>
                                                Created on  {getLocalDateStringFromUtcDateString(document.createdOn, 'DD MMM YYYY hh:mm A')}

                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <Collapse isOpen={document.isOpenCollapse}>
                                    <CardBody>
                                        <Row>
                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}
                                                className={"border-right mt-2"}>
                                                <div>
                                                    <FormGroup>
                                                        <Label for="title">Title</Label>
                                                        <Input type="text" name="title" id="title" plaintext={!isEditingAllowed}
                                                            placeholder="Type a title here..."
                                                            value={document.title || ''}
                                                            onChange={(e) => this.handleChange(index, e.target.value, "title")}
                                                            autoComplete="off" />
                                                        <FormText color="danger"> {formErrors[index] ? formErrors[index].title : ''}</FormText>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Supplier</Label>
                                                        <SearchSmartHomeCreditor
                                                            handleAccountChange={(supplierCode) => this.handleChange(index, supplierCode, "selectedSupplierCode")}
                                                            selectedAccountID={document.selectedSupplierCode}
                                                            defaultAccountID={document.selectedSupplierCode} />

                                                        {(document.supplierCodes || []).map((supplierCode, supplierIndex) => (
                                                            <Badge key={supplierIndex}
                                                                color="primary"
                                                                className={"m-1"}
                                                            >{supplierCode} <i className='fa fa-times ml-2 hand-cursor'
                                                                onClick={() => this.handleDeleteSupplier(index, supplierIndex)} /></Badge>
                                                        ))}
                                                        <FormText color="danger"> {formErrors[index] ? formErrors[index].supplierCode : ''}</FormText>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="description">Description</Label>
                                                        <Input type="textarea" name="description" id="description" plaintext={!isEditingAllowed}
                                                            placeholder="Type a description here..."
                                                            value={document.description || ''}
                                                            onChange={(e) => this.handleChange(index, e.target.value, "description")}
                                                            autoComplete="off" />
                                                        <FormText color="danger"> {formErrors[index] ? formErrors[index].description : ''}</FormText>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Tag</Label>
                                                        <CreatableSelect
                                                            isMulti
                                                            formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                            placeholder={"Add file tag"}
                                                            onChange={(value) => this.handleChange(index, value, "shpTags")}
                                                            value={document.selectedFileTagsArray}
                                                            options={fileTagOptions}
                                                        />
                                                        <FormText color="danger"> {formErrors[index] ? formErrors[index].tag : ''}</FormText>
                                                    </FormGroup>
                                                    <UploadFiles
                                                        isReadOnly={!isEditingAllowed}
                                                        handleChange={(change) => this.handleChange(index, change, "attachmentIDs")}
                                                        attachmentIDs={document.attachmentIDs}
                                                        isDownloadAllFilesBtnVisible={true}
                                                        documentID={document.id} />

                                                </div>
                                            </Col>
                                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                                <Table hover bordered size={"sm"} responsive style={{ minHeight: '300px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '180px' }}>Fineline</th>
                                                            <th style={{ minWidth: '150px' }}>Prodcode</th>
                                                            <th style={{ minWidth: '110px' }}>Sub class</th>
                                                            <th style={{ minWidth: '110px' }}>Sub department</th>
                                                            <th style={{ minWidth: '80px' }} className='text-center'>
                                                                <Button size='sm' onClick={() => this.handleAddFinelineItems(index)}>Add <i className='fa fa-plus mr-2' /></Button></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(document.shpDocumentFinelineItems || []).map((fineline, i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    <FormGroup>
                                                                        <SearchFineLine handleSelection={(value) => this.handleFineLineSelection(index, i, value)} defaultValue={fineline.swishDWDimStockItemID} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td>
                                                                    <Input type="text"
                                                                        placeholder='Enter prodcode here'
                                                                        onChange={(e) => this.handleFinelineChange(index, i, e.target.value, "prodCode")}
                                                                        value={fineline.prodCode || ''} />
                                                                    <FormText color="danger"> {formErrors[index] ? formErrors[index].prodCode : ''}</FormText>

                                                                </td>
                                                                <td>
                                                                    <Input type="text"
                                                                        placeholder='Enter sub class here'
                                                                        onChange={(e) => this.handleFinelineChange(index, i, e.target.value, "subClass")}
                                                                        value={fineline.subClass || ''} />
                                                                </td>
                                                                <td>
                                                                    <Input type="text"
                                                                        placeholder='Enter sub department here'
                                                                        onChange={(e) => this.handleFinelineChange(index, i, e.target.value, "subDepartment")}
                                                                        value={fineline.subDepartment || ''} />
                                                                </td>
                                                                <td className='text-center'>
                                                                    <Button color='danger' size='sm' onClick={() => this.handleDeleteFineline(index, i)}>
                                                                        <i className='fa fa-trash' />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>

                                                <FormText color="danger"> {formErrors[index] ? formErrors[index].shpDocumentFinelineItems : ''}</FormText>

                                                <FormGroup>
                                                    <Label for="description">Notes</Label>
                                                    <div className="quil-editor-root ">
                                                        {isEditingAllowed ? < ReactQuill value={document.notes}
                                                            readOnly={!isEditingAllowed}
                                                            placeholder="Type description here..."
                                                            modules={isEditingAllowed ? Editor.modules : {}}
                                                            formats={isEditingAllowed ? Editor.formats : []}
                                                            onChange={(change) => this.handleChange(index, change, "notes")}
                                                            bounds={'.quil-editor-root'} />
                                                            :
                                                            <ReactQuill
                                                                value={document.notes}
                                                                readOnly={true}
                                                                theme={"bubble"}
                                                            />}
                                                    </div>
                                                    <FormText color="danger"> {formErrors[index] ? formErrors[index].notes : ''}</FormText>
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                        <br />
                                        {document.createdByUser
                                            ? <div className="text-muted">Posted
                                        &nbsp;by<i className="fa fa-user-o ml-2 mr-1"
                                                    aria-hidden="true" />{document.createdByUser.name || ''}
                                        &nbsp;on<i className="fa fa-calendar ml-2 mr-1"
                                                    aria-hidden="true" />{getLocalDateStringFromUtcDateString(document.createdOn, 'DD MMM YYYY hh:mm A')}
                                       &nbsp; ({this.getTime(document.createdOn)})
                                    </div>
                                            : null
                                        }
                                        {
                                            document.lastModifiedByUser
                                                ? <div className="mt-2 text-muted">Last updated
                                            &nbsp;by<i className="fa fa-user-o ml-2 mr-1"
                                                        aria-hidden="true" />{document.lastModifiedByUser.name || ''}
                                            &nbsp;on<i className="fa fa-calendar ml-2 mr-1"
                                                        aria-hidden="true" />{getLocalDateStringFromUtcDateString(document.lastModifiedOn, 'DD MMM YYYY hh:mm A')}
                                            &nbsp; ({this.getTime(document.lastModifiedOn)})
                                        </div>
                                                : null
                                        }
                                        <div>
                                            {document.requestProgressPercentage > 0
                                                ? <Progress striped color="primary" value={document.requestProgressPercentage}
                                                    className={"mt-2"} />
                                                : null
                                            }
                                        </div>
                                        <br />


                                        <Link className='btn btn-primary btn-sm'
                                            onClick={() => this.onSelectHistory(document)}
                                            to={"/shp/document/version/history?" + queryString.stringify({ uuid: document.id })}>
                                            <i className='fa fa-clock-o mr-2' />
                                            View history</Link>

                                        <br />


                                    </CardBody>

                                    <CardFooter>

                                        <div className="text-right">
                                            {
                                                isEditingAllowed
                                                    ? <Button color="primary" onClick={() => this.saveDocument(index)} size={"sm"}
                                                        disabled={document.saving}>
                                                        {document.saving
                                                            ? <Spinner size="sm"
                                                                className={"mr-2"}
                                                                style={{ color: "white" }} />
                                                            : <i className="fa fa-floppy-o mr-2" />
                                                        }
                                                        {document.saving
                                                            ? "Saving"
                                                            : "Save"
                                                        }

                                                    </Button>
                                                    : null
                                            }

                                            <Button color="secondary" className="ml-2" size={"sm"}
                                                onClick={() => this.toggleCollapse(index, !document.isOpenCollapse)}>
                                                <i className="fa fa-times mr-2" />Close</Button>
                                        </div>
                                    </CardFooter>
                                </Collapse>
                            </Card>
                        ))}
                    </div>}

                <SHPVersionControlModal isOpen={this.state.isOpenVersionModal}
                    toggle={this.toggleVersionModal} req={this.state.req} handlerCloseModal={this.handleCloseModal}
                />

                <ToastContainer/>
            </div>
        );
    }
}