import { config } from '../helpers/config'
import { authHeader } from '../helpers/auth-header';
import * as axiosFileDownloader from "axios";


class HttpService {

    static Instance() {
        //File downloader api call   --downloading slow
        let ax = new axiosFileDownloader.create({
            baseURL: config.apiUrl,
            timeout: 300000,
            headers: authHeader(),
            responseType: 'blob',
        });

        ax.interceptors.request.use(config => {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user && user.token) {
                config.headers.Authorization = "Bearer " + user.token
            }
            return config
        });

        return ax;
    }
}
//https://gist.github.com/alfonmga/96474f6adb6ed8dee8bc8bf8627c0ae1
export default HttpService.Instance()