import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, Col, Input, InputGroup, Breadcrumb, BreadcrumbItem,
    InputGroupAddon, InputGroupText, Row, Spinner, Table, ListGroup, ListGroupItem, Button, Badge
} from 'reactstrap';
import classnames from 'classnames';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileSaver from 'file-saver';
import { getLocalDateStringFromUtcDateString, getDateString,handleErrorMessage } from '../../services/CommonService';
import SHPDocumentsService from '../../services/SHP/SHPDocumentsService';
import queryString from 'query-string';
import SHPSnapshotModal from '../modal/SHPSnapshotModal';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default class ManageDocumentHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            documentID: this.props.documentID,
            items: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 20
                    },
                    sortRequest: {
                        key: "createdOn",
                        direction: false
                    },
                    filterRequest: {
                        Event: '',
                        DocumentId: 0,
                        version:0,
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: false,
            selectedHistory: {},
            isOpenSnapshotModal: false,
        };

        this.refresh = this.refresh.bind(this);
        this.getitems = this.getitems.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.showAttachments = this.showAttachments.bind(this);
        this.handleFileDownload = this.handleFileDownload.bind(this);
        this.toggleSnapshotModal = this.toggleSnapshotModal.bind(this);
    }

    
    componentDidMount() {
        let { documentID, items } = this.state;
        items.request.filterRequest.DocumentId = documentID;
        this.setState({ items }, () => this.refresh(items));
    }

    componentWillReceiveProps(nextProps) {
        let { items } = this.state;
        if (nextProps.documentID !== this.state.documentID) {
            items.request.filterRequest.DocumentId = nextProps.documentID;
            this.setState({ items, documentID: nextProps.documentID }, () => this.refresh(items));
        }

        if (nextProps.refreshAgainHistory) {
            this.refresh(items);
        }
    }

    refresh(items) {
        this.getitems(items);
    }

    getitems(items) {
        this.setState({ loading: true });

        let request = cloneDeep(items.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }

        SHPDocumentsService.getHistoryForParticularDocument(request).then(response => {
             if (response.status === 200 || response.status === '200') {
                let { items } = this.state;
                items.response = response.data;
                this.setState({ items, loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    handleFileDownload(item, documentID) {
        if (item.id) {
            SHPDocumentsService.DownloadAttachment(item.id, documentID, null).then(response => {
                FileSaver.saveAs(response.data, item.description);
            }).catch(error => {
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                console.log(error);
            });
        }
    }

    showAttachments(attachments, SHPDocumentId) {
        if (attachments) {
            return (
                <ListGroup flush>
                    {(attachments || []).map((a, index) => {
                        return <ListGroupItem className={"hoverableItem"} key={index}>
                            <Row className={"align-items-center"}>
                                <Col xl={{ size: 'auto' }}
                                    lg={{ size: 'auto' }}
                                    md={{ size: 'auto' }}
                                    sm={{ size: 'auto' }}
                                    xs={{ size: 'auto' }}
                                    className={"pl-0"}>
                                    <Button color="link" size={"sm"}
                                        title={"Click here to download"}
                                        onClick={() => this.handleFileDownload(a, SHPDocumentId)}>
                                        <i className="fa fa-paperclip mr-1" aria-hidden="true" /> {a.description}
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    })}
                </ListGroup>
            )
        }
    }

    handleClickVersion(version) {
        this.props.handleChangeVersion(version);
    }

    getColValue(value, storeItem, order, index) {
        let createdByText = '';
        switch (order.eventName) {
            case "DOWNLOAD":
                createdByText = 'Downloaded by ' + (order.createdByUser ? order.createdByUser.name : '');
                break;

            case "CREATE":
                createdByText = 'Created by ' + (order.createdByUser ? order.createdByUser.name : '');
                break;

            case 'UPDATED_WITH_SAME_VERSION':
                 createdByText = 'Updated by ' + (order.createdByUser ? order.createdByUser.name : '');
                break;

            case 'UPDATED_WITH_NEW_VERSION':
                createdByText = 'New version updated from ' +
                (order.shpDocument.version - 1 + ' to ' + order.shpDocument.version) +
                    ' by ' + (order.createdByUser ? order.createdByUser.name : '');
                break;
        }

        switch (storeItem.type) {
            case "date":
                return <div>
                    <div className={"text-right"}>
                        {getLocalDateStringFromUtcDateString(value, "DD MMM YYYY")}
                    </div>
                    <div className={"text-right"}>
                        {getLocalDateStringFromUtcDateString(value, "hh:mm A")}
                    </div>
                </div>
            case "createdBy":
                return order.createdByUser ? order.createdByUser.name : '';
            case "createdByText":
                return <small>{createdByText}</small>;
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "DocDescription":
                if (order) {
                    if (order.shpDocument && order.shpDocument.description) {
                        return <span>{order.shpDocument.description}</span>
                    } else {
                        return <span></span>
                    }
                }
            case "Doctitle":
                if (order) {
                    if (order.shpDocument && order.shpDocument.title) {
                        return <span>{order.shpDocument.title}</span>
                    } else {
                        return <span></span>
                    }
                }
            case "version":
                if (order) {
                    if (order.shpDocument && order.shpDocument.version) {
                        return <span className='text-center'>

                            <Button color='link' title='click here to view document version' onClick={() => this.handleClickVersion(order.shpDocument.version)}>
                                {order.shpDocument.version}</Button> 
                            </span>
                    } else {
                        return <span></span>
                    }
                }
            case "possibleEvent":
                switch (order.eventName) {
                    case "DOWNLOAD":
                        return <span className='text-center'><Badge color='success'>Download</Badge></span>;
                    case "CREATE":
                        return <span className='text-center'><Badge color='info'>Create</Badge></span>;
                    case "UPDATED_WITH_SAME_VERSION":
                        return <span className='text-center'><Badge color='light'>Updated with same version</Badge></span>;
                    case "UPDATED_WITH_NEW_VERSION":
                        return <span className='text-center'><Badge color='warning'>Updated with new version</Badge></span>;
                    default:
                        return <span className='text-center'><Badge>{order.eventName}</Badge></span>;
                }
            case "SnapShotsBeforeLast":
                return this.showAttachments(order.beforeSnapshots, order.shpDocument ? order.shpDocument.id : null);
            case "SnapShotsAfterLast":
                return this.showAttachments(order.afterSnapshots, order.shpDocument ? order.shpDocument.id : null);
            case "beforeevent":

                if (order.eventName === 'UPDATED_WITH_SAME_VERSION' || order.eventName === 'UPDATED_WITH_NEW_VERSION') {
                    return <div>
                        <Button color='primary' block size='sm' onClick={() => this.openSnapshotModal(order.beforeEventSHPDocumentSnapshot, order.shpDocument.version)}> View snapshot</Button>
                    </div>
                }
                else {
                    return <div>
                        <Button color='primary' block size='sm'
                            onClick={() => this.openSnapshotModal(order.beforeEventSHPDocumentSnapshot, order.shpDocument.version)}>{order.eventName === 'CREATE' ?
                            'View created document' : 'View downloaded document'}</Button>
                    </div>
                }

            case "afterevent":
                if (order.eventName === 'UPDATED_WITH_SAME_VERSION' || order.eventName === 'UPDATED_WITH_NEW_VERSION') {
                    return <div>
                        <Button color='primary' block size='sm' onClick={() => this.openSnapshotModal(order.afterEventSHPDocumentSnapshot, order.shpDocument.version)}> View snapshot</Button>
                    </div>
                }
            default:
                return <span>{value}</span>
        }
    }

    openSnapshotModal(doc, version) {
        this.setState({ isOpenSnapshotModal: true, selectedHistory: doc, selectedVersion: version });
    }

    toggleSnapshotModal(change) {
        this.setState({ isOpenSnapshotModal: change });
    }

    handleChange(change, key) {
        let { items } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (items.request.sortRequest.key === change) {
                    items.request.sortRequest.direction = !items.request.sortRequest.direction;
                } else {
                    items.request.sortRequest.key = change;
                    items.request.sortRequest.direction = false;
                }

                this.getitems(items);
                break;
            case "pageSize":
                items.request.pageRequest[key] = change;
                this.getitems(items);
                break;
            case "currentPage":
                items.request.pageRequest[key] = change;
                this.getitems(items);
                break;
            default:
                items.request.filterRequest[key] = change;
                if (key === 'version') {
                    if (change === '') {
                        items.request.filterRequest[key] = 0;
                    }
                    else {
                        items.request.filterRequest[key] = change;
                    }
                }
                items.request.pageRequest.currentPage = 1;
                this.setState({ items });
                this.getitems(items);
        }
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();
        }
        return flag;
    }

    getStore({ filterRequest }) {
        let type = this.props.type;
        return [
            {
                key: "createdOn",
                label: "Date",
                type: "date",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <div className={"text-right mb-1"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={filterRequest.startDate}
                            onChange={date => this.handleChange(date, "startDate")}
                            selectsStart
                            startDate={filterRequest.startDate}
                            endDate={filterRequest.endDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="Start date"
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                    <div className={"text-right"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={filterRequest.endDate}
                            onChange={date => this.handleChange(date, "endDate")}
                            selectsEnd
                            startDate={filterRequest.startDate}
                            endDate={filterRequest.endDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="End date"
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                </div>
            },
            {
                key: "createdBy",
                label: "Made by",
                type: "createdBy",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "event",
                label: "Type",
                type: "possibleEvent",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type='select' placeholder='Select event type' onChange={(e) => this.handleChange(e.target.value, 'event')}>
                        <option value='ALL'>All</option>
                        <option value='CREATE'>Create</option>
                        <option value='UPDATED_WITH_SAME_VERSION'>Updated with same version</option>
                        <option value='UPDATED_WITH_NEW_VERSION'>Updated with new version</option>
                    </Input>
                </div>
            },
            {
                key: "createdByText",
                label: "Description",
                type: "createdByText",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },
            
            /*{
                key: "",
                label: "Document Description",
                type: "DocDescription",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "",
                label: "Document title",
                type: "Doctitle",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },*/
            {
                key: "version",
                label: "Document version",
                type: "version",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: <Input type='number' value={filterRequest.version || ''}
                    name='version' onChange={(e) => this.handleChange(e.target.value, 'version')}/>
            },
            /*{
                key: "",
                label: "Before Last Event", //SnapShotsBeforeLast
                type: "SnapShotsBeforeLast",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "",
                label: "After Last Event", //SnapShotsAfterLast
                type: "SnapShotsAfterLast",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },*/
            {
                key: "",
                label: "Before event", //SnapShotsAfterLast
                type: "beforeevent",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "",
                label: "After event", //SnapShotsAfterLast
                type: "afterevent",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                showColumn: true,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            }
        ];
    }


    render() {
        let { items, loading, searchText } = this.state;
        let { pageRequest, sortRequest, filterRequest } = this.state.items.request;


        let store = this.getStore(items.request);

        return (
            <div>

                {/*<Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <h5>SHP document history</h5>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>*/}
                        <Row className={"align-items-center"}>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ?
                                        <span>
                                            <Spinner size={"sm"} color={"primary"} />
                                        </span>
                                        :
                                        <span>
                                            Showing{' '}
                                            {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {items.response.totalRecords}
                                            {' '}entries
                                </span>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-2">
                            <Table hover bordered size={"sm"} striped responsive>
                                <thead>
                                    <tr>
                                        {(store || []).filter(item => item.showColumn ? true : false).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(store || []).filter(item => item.showColumn ? true : false).map((item, index) => {
                                            return (
                                                    <td key={index} colSpan={item.searchNodeColSpan}
                                                        className={"align-middle"}>
                                                        {item.searchNode}
                                                    </td>
                                                );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(items.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, i) => {
                                        return (
                                            <tr key={i}>
                                                {(store || []).filter(item => item.showColumn ? true : false).map((storeItem, index) => {
                                                    return (
                                                        <td key={index} className={storeItem.valueClassName}>
                                                            {this.getColValue(order[storeItem.key], storeItem, order, i)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>

                            <br/>
                            {!loading ?
                                <Row>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <div className={"text-left"} style={{ maxWidth: 200 }}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>Show</InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type={"select"}
                                                    name={"pageSize"}
                                                    value={pageRequest.pageSize}
                                                    disabled={loading}
                                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                                    <option value={10}>10 Rows</option>
                                                    <option value={25}>25 Rows</option>
                                                    <option value={50}>50 Rows</option>
                                                    <option value={100}>100 Rows</option>
                                                    <option value={500}>500 Rows</option>
                                                </Input>
                                            </InputGroup>


                                        </div>
                                    </Col>
                                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                        <div className={"float-right"}>
                                            <Pagination
                                                activePage={pageRequest.currentPage}
                                                itemsCountPerPage={pageRequest.pageSize}
                                                totalItemsCount={items.response.totalRecords}
                                                pageRangeDisplayed={3}
                                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                                itemClass='page-item'
                                                linkClass='page-link'
                                                activeClass='active'
                                                innerClass='pagination'
                                                activeLinkClass='active'
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                :
                                null
                            }
                        </div>

                    
                <SHPSnapshotModal isOpen={this.state.isOpenSnapshotModal}
                        version={this.state.selectedVersion}
                        toggle={this.toggleSnapshotModal} snapshot={this.state.selectedHistory}
                    />
                    <ToastContainer />
            </div>
        );
    }
}